import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        burger: {
            marginRight: 'auto',
        },
        openContainer: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                marginRight: 'auto'
            },
        },
        open: {
            display: 'flex',
            alignItems: 'center',

            '& .nav:not(:last-child)': {
                marginRight: '30px'
            },

            [theme.breakpoints.up('md')]: {
                '& .nav:not(:last-child)': {
                    marginRight: '60px'
                },
            }
        },
        hidden: {
            paddingTop: theme.spacing(), paddingBottom: theme.spacing(),
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',

            '& .nav:not(:last-child)': {
                marginBottom: theme.spacing()
            }
        },
        nav: {
            position: 'relative',
            textDecoration: 'none',
            transition: 'color 200ms ease-in-out'
        }
    })
);
