import { FC, Suspense, lazy, useState } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, Slide } from '@mui/material';
import { NotFound } from '../components/utils';
import { getByKey, saveOrUpdate } from '../state/Storage';
import { Auth } from './auth';
import { Dashboard } from './dashboard';
import { Home } from './home';
import { Journals } from './journals';
import { Membership } from './members';
import { Notifications } from './notifications';
import { Profile } from './profile';
import { Plans } from './plans';
import { Marks } from './marks';
import { Search } from './search';
import { Subscriptions } from './subscriptions';
import { Users } from './users';
import { StaffGuides } from './guides';
import { Jobs } from './jobs';
import { Invoices } from './invoices';
import { DemoVideo } from './demo-video/DemoVideo';
import { Logs } from './logs';


const ContactUsPage = lazy(() => import('./contact-us/ContactUsPage'));
const EmailUnsubscribe = lazy(() => import('./email/Unsubscribe'));
const VerifyEmail = lazy(() => import('./email/VerifyEmail'));
const EmailVerified = lazy(() => import('./email/EmailVerified'));
const TermsAndConditions = lazy(() => import('./terms/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./privacy/PrivacyPolicy'));

const COOKIE = 'COOKIE';

export const Pages: FC<Pick<RouteProps, 'path'>> = ({ path }) => {
    const [showCookie, setShowCookie] = useState(!Boolean(getByKey(COOKIE)));

    const acceptCookies = () => {
        saveOrUpdate(COOKIE, true);
        setShowCookie(false);
    }

    return <Route path={path}>
        <Suspense fallback={PageCircularLoader}>
            <Switch>
                <Auth path={'/auth/'} />
                <Dashboard path={'/dashboard'} exact />
                <Journals path={'/journals'} />
                <Jobs path={'/jobs'} />
                <Search path={'/search/'} />
                <Membership path={'/members/'} exact />
                <Notifications path={'/notifications'} exact />
                <Subscriptions path={'/subscriptions'} />
                <Invoices path={'/invoices'} />
                <Profile path={'/profile'} exact />
                <Plans path={'/plans'} exact />
                <Marks path={'/marks'} />
                <Users path={'/users'} />
                <Route path={'/contact-us'} component={ContactUsPage} exact />
                <Route path={'/email-unsubscribe/:tokenId'} component={EmailUnsubscribe} exact />
                <Route path={'/verify-email'} component={VerifyEmail} exact />
                <Route path={'/email-verified'} component={EmailVerified}  exact />
                <StaffGuides path={'/staff-guides'} />
                <Route path={'/terms-and-conditions'} component={TermsAndConditions} exact />
                <Route path={'/privacy-policy'} component={PrivacyPolicy} exact />
                <Route path={'/product-tour'} component={DemoVideo} exact />
                <Route path={'/logs'} component={Logs} exact />
                <Home path={'/'} exact />
                <Route path='*'><NotFound /></Route>
            </Switch>
        </Suspense>

        <Slide direction="up" in={showCookie} mountOnEnter unmountOnExit>
            <Box
                position='fixed'
                left={0}
                right={0}
                bottom={0}
            >
                <Paper variant={'outlined'}>
                    <Box className='flex-center' py={2} height={'5rem'}>
                        <Box
                            px={2}
                            display='flex'
                            flexWrap='wrap'
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Box pb={1} mr={4}>
                                This site uses cookies to offer you a better browsing experience.
                            </Box>

                            <Box display='flex'>
                                {/* <Box mr={2}>
                                    <Button
                                        size='small'
                                    >
                                        Reject
                                    </Button>
                                </Box> */}

                                <Button
                                    onClick={acceptCookies}
                                    color='primary'
                                    variant='contained'
                                >
                                    Accept All Cookies
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Slide>
    </Route>
}

const PageCircularLoader = (
    <Box className='flex-center' minHeight='100vh'>
        <CircularProgress />
    </Box>
)
