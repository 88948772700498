import React from "react";
import { Box, Container, Link as MuiLink, useTheme } from "@mui/material";
import { Link } from "react-router-dom"
import JeetrakaLogo from "../../assets/jeetraka_logo_light.svg";

export const AuthContainer: React.FC<{ children: any }> = ({ children }) => {
  const theme = useTheme();

  return <Box minHeight={'100vh'}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    style={{
      backgroundColor: theme.palette.background.default
    }}
  >
    <Container
      sx={{
        mt: 6,
        mb: 10,
        width: '100%'
      }}
    >
      <MuiLink
        component={Link}
        color='inherit'
        to='/'
      >
        <Box
          component='img'
          src={JeetrakaLogo}
          alt='Jeetraka'
          sx={{}}
        />
      </MuiLink>
    </Container>

    {children}
  </Box>
}
