import React, { useCallback, useRef } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useLogError } from "../../api";
import { UserHeader } from "../headers";
import { useCurrentUser } from "../../state/AppState";
import { useLocation } from "react-router-dom";

export const PageErrorBoundary: React.FC<{ children: any }> = ({ children }) => {
    const dedupRef = useRef<string | null>(null);
    const { request } = useLogError();
    const { user } = useCurrentUser();
    const location = useLocation();
 
    const logError = useCallback((error: any, _errorInfo: any) => {
        if (dedupRef.current === error.message) return;
        let userAgent = navigator.userAgent;
        if ('userAgentData' in navigator) {
            const userAgentData = navigator.userAgentData as any;
            if (('brands' in userAgentData) && Array.isArray(userAgentData.brands))
            userAgent = userAgentData.brands.map((b: any) => `${b.brand} ${b.version}`).join(', ');
        }
        request({
            source: 'client',
            error: error.message,
            errorInfo: {
                pageUrl: `${location.pathname}${location.search}`,
                user: user && {
                    _id: user._id,
                    email: user.email
                },
                userAgent
            }
        }).catch(err => console.log(err));
        dedupRef.current = error.message;
    }, []);

    return <ErrorBoundary component={<ErrorPage />} logError={logError}>
        {children}
    </ErrorBoundary>
}

const ErrorPage: React.FC = () => {
    const theme = useTheme();

    return <Box
        bgcolor={theme.palette.background.default}
        minHeight={'100vh'}
        pb={8}
    >
        <UserHeader />

        <Box mt={'20vh'}>

            <Typography align='center'>
                <Box
                    component='span'
                    color='#505050'
                    fontSize={'2rem'}
                >
                    An Unexpected Error has Occured
                </Box>
            </Typography>

            <Box mt={2}
                display='flex'
                justifyContent='center'
            >
                <Button
                    onClick={window.location.reload}
                    color='primary'
                >
                    Try reloading this page
                </Button>
            </Box>

        </Box>
    </Box>
}


interface ErrorBoundaryProps {
    logError: (error: any, errorInfo: any) => void;
    component: JSX.Element;
    children: any;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.props.logError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.component;
        }

        return this.props.children;
    }
}
