import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'hidden',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        searchBar: {
            paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4),
            width: '100%',
            marginLeft: 'auto', marginRight: 'auto'
        },
        content: {
            position: 'relative',
            flex: 1,

            '& .pad': {
                paddingLeft: theme.spacing(), paddingRight: theme.spacing(),
            }
        },
        results: {
        },
        payment: {
            zIndex: 2,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: 'linear-gradient(to bottom, transparent, 5%, white 50% 100%)',
            paddingTop: '20vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    })
);
