import { Transition } from "react-spring/renderprops";


export interface FadeTransitionProps {
    className?: string
    show?: boolean,
    stop?: number,
    items: JSX.Element[],
    style?: React.CSSProperties
    align?: 'center' | 'start' | 'stretch'
}

export const FadeTransition: React.FC<FadeTransitionProps> =
({ show, stop = 1, items, align = 'center', ...props }) => {
    return <div {...props}>
        <Transition
            items={show}
            from={{
                position: 'absolute',
                opacity: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: align,
                justifyContent: 'center'
            } as React.CSSProperties}
            enter={{ opacity: stop }}
            leave={{ opacity: 0 }}>
            {show => show
                ? items[0] && (props => <div style={props}>{items[0]}</div>)
                : items[1] && (props => <div style={props}>{items[1]}</div>)}
        </Transition>
    </div>
}
