import { useState } from "react";
import {
  Theme,
  Box,
  Button,
  Container,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
  InputAdornment,
  useTheme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import EmailIcon from '../assets/email.svg';
import JeeTrakaLogoDark from '../assets/jeetraka_logo_dark.svg';
import { useNewsLetterSubcribe } from "../api";

export const Footer: React.FC = () => {
  const theme = useTheme();
  const { loading, request } = useNewsLetterSubcribe();
  const [email, setEmail] = useState('');

  return <Box
    sx={{
      color: 'white',
      backgroundColor: 'secondary.main',
      py: '90px',
    }}
  >
    <Container
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap-reverse',
        [theme.breakpoints.up('lg')]: {
          flexWrap: 'wrap'
        }
      }}
    >
      <Box sx={{ mr: 'auto' }}>
        <Typography variant='h2' sx={{ color: 'white' }}>
          Subscribe to Our<br />
          Newsletter
        </Typography>
        <Typography variant='subtitle1' sx={{ color: 'white' }}>
          A digest of latest news, articles and resources
        </Typography>

        <Typography>Email</Typography>
        <TextField
          placeholder="Enter your email address"
          InputProps={{
            endAdornment: <InputAdornment position='end'>
              <img alt='email' src={EmailIcon} />
            </InputAdornment>
          }}
          sx={{
            my: 2,
            backgroundColor: 'white',
            width: '100%',
            borderRadius: '10px'
          }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant='contained'
          sx={{ width: '100%', height: '55px' }}
          onClick={() => {
            request({ email })
              .then(() => setEmail(''))
              .catch((err) => console.log(err));
          }}
          disabled={loading || !email}
        >
          Submit
        </Button>

        <Typography sx={{ fontWeight: 500, mt: 6 }}>
          © 2023 JeeTraka. All rights reserved
        </Typography>
      </Box>

      <Box sx={{
        textAlign: 'left',
        width: '100%',
        maxWidth: '580px',
        mb: 6,
        [theme.breakpoints.up('lg')]: {
          textAlign: 'right',
          mb: 0
        }
      }}>
        <Box component='img' src={JeeTrakaLogoDark} alt='jeetraka' />
        <MuiLink href={`mailto:jeetraka@jeetraka.com`} sx={{
          my: 3,
          display: 'block',
          color: 'white',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}>
          jeetraka@jeetraka.com
        </MuiLink>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('lg')]: {
              justifyContent: 'flex-end',
            }
          }}
        >
          <MuiLink href={"https://x.com/jeetraka_"}>
          <IconButton
              sx={{
                borderRadius: '100%',
                backgroundColor: 'primary.main',
                color: 'white',
              }}
            >
              <TwitterIcon />
            </IconButton>
          </MuiLink>

          <MuiLink href={"https://www.linkedin.com/company/jeetraka/?viewAsMember=true"}>
            <IconButton
              sx={{
                borderRadius: '100%',
                backgroundColor: 'primary.main',
                color: 'white',
                mx: 2,
              }}
            >
              <LinkedInIcon />
            </IconButton>
          </MuiLink>

          <MuiLink href={"https://www.instagram.com/jeetraka"}>
            <IconButton
              sx={{
                borderRadius: '100%',
                backgroundColor: 'primary.main',
                color: 'white'
              }}
            >
              <InstagramIcon />
            </IconButton>
          </MuiLink>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mt: 3,
            [theme.breakpoints.up('lg')]: {
              justifyContent: 'flex-end',
            }
          }}
        >
          <MuiLink
            component={Link}
            to='/terms-and-conditions'
            sx={{ color: 'white', textDecorationColor: 'white' }}
          >Terms and Conditions</MuiLink>

          <Box sx={{
            backgroundColor: 'white',
            borderRadius: '999px',
            width: '4px', height: '4px',
            mx: 2,
          }}></Box>

          <MuiLink
            component={Link}
            to='/privacy-policy'
            sx={{ color: 'white', textDecorationColor: 'white' }}
          >Privacy Policy</MuiLink>
        </Box>
      </Box>
    </Container>
  </Box>
}

export const OldFooter: React.FC = () => {
  const classes = useStyles();

  return <Box className={classes.footer} component='footer'>
    <Container>
      <div className={'main'}>

        <div className='section'>
          <Typography className='title' variant='h6'>Corporate</Typography>

          <Typography variant='body2' component='div'>
            <div className='item'>
              <a href="https://jee.africa/who-we-are/">About Us</a></div>
            <div className='item'>
              <a href="https://jee.africa/our-people">Find a Lawyer</a></div>
            <div className='item'>
              <a href="https://jee.africa/practice-areas">Services</a></div>
          </Typography>
        </div>

        <div className='section'>
          <Typography className='title' variant='h6'>The Practice</Typography>

          <Typography variant='body2' component='div'>
            <div className='item'>
              <a href="https://jee.africa/practice-areas/">Practice Areas</a></div>
            <div className='item'>
              <a href="https://jee.africa/sectors/">Sectors</a></div>
            <div className='item'>
              <a href="https://jee.africa/our-awards/">Our Awards</a></div>
          </Typography>
        </div>

        <div className='section'>
          <Typography className='title' variant='h6'>News & Resources</Typography>

          <Typography variant='body2' component='div'>
            <div className='item'>
              <a href="https://jee.africa/articles-publications/">
                Articles & Publications
              </a>
            </div>
            <div className='item'>
              <a href="https://jee.africa/gallery/">Gallery</a></div>
            <div className='item'>
              <a href="https://jee.africa/thought-leadership/">
                Thought Leadership
              </a>
            </div>
          </Typography>
        </div>

        <div className='section'>
          <Typography className='title' variant='h6'>Contact</Typography>

          <Typography variant='body2' component='div'>
            <div className='item'>
              RCO Court, 3-5 Sinari Daranijo Street, Off Ajose Adeogun, Victoria Island, Lagos, Nigeria.
            </div>

            <div className='item'>
              Tel: +234 1 4626841/3</div>

            <div className='item'>
              <a href='mailto:jee@jee.africa'>
                jee@jee.africa</a>
            </div>

            <div className='item'>
              <span style={{ marginRight: '0.5rem' }}>Lagos</span>
              <span style={{ marginRight: '0.5rem' }}>Abuja</span>
              <span style={{ marginRight: '0.5rem' }}>Ikeja</span>
              <span style={{ marginRight: '0.5rem' }}>Ghana</span>
            </div>
          </Typography>
        </div>

      </div>

      <Typography className='end' variant='overline' component='div'>
        <span>copyright © 2019.</span>
        <span>jackson, etti & edu</span>
        <span className='border'>
          <a href="https://jee.africa/privacy-policy/" style={{ color: 'white' }}>
            privacy policy</a>
        </span>
        {/* <span>powered by Novo</span> */}
      </Typography>
    </Container>
  </Box>
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: '#1e1e1e',
      paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4),

      '& .main': {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2),
        borderBottom: '2px solid hsla(0,0%,100%,.15)',

        '& .section': {
          minWidth: '8rem',
          maxWidth: '18rem',

          '& .title': {
            color: 'white',
            marginBottom: '1.2rem',
          },

          '& .item': {
            color: '#a6a6a6',

            '& > a': {
              color: 'white'
            }
          },

          '& .item:not(:last-child)': {
            marginBottom: '1rem'
          },

        },

        '& .section:not(:last-child)': {
          marginBottom: '1.5rem',
        },

        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      },

      '& .end': {
        color: '#a6a6a6',
        paddingTop: '1rem',

        '& > span:not(:first-child)': {
          paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2),
        },

        '& .border': {
          borderLeft: '1px solid',
          borderRight: '1px solid'
        }
      }
    }
  })
);
