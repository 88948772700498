import { lazy, memo } from "react";
import { Switch, Redirect, Route, RouteProps } from "react-router";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";
import { GuideContainer } from "./GuideContainer";

const STAFF_GUIDES = [
    'Introduction',
    'Marks',
    'Plans',
    'Users'
]

const StaffGuidesRoutes: React.FC<{ path: RouteProps['path'] }> = memo(({ path }) => (
    <>
        {
            STAFF_GUIDES.map((title, index) => {
                const Child = lazy(() => import(`./${title}`));
                return <Route
                    key={index}
                    path={`${path}/${title.toLowerCase()}`}
                >
                    <GuideContainer current={title} guides={STAFF_GUIDES} path={`${path}`}>
                        <Child />
                    </GuideContainer>
                </Route>
            })
        }

        {
            STAFF_GUIDES.length > 0 &&
            <Redirect from={`${path}/`} to={`${path}/${STAFF_GUIDES[0].toLowerCase()}`} />
        }
    </>
), (prevProps, nextProps) => prevProps.path === nextProps.path);

export const StaffGuides: React.FC<RouteProps> = ({ path, ...props }) => {
    const { user, ready } = useCurrentUser(true);

    let showGuides = ready && user?.permissions.guides === 'staff';

    return <Route path={path} {...props}>
        <Switch>
            {!showGuides && <Route path='*'><NotFound /></Route>}
            <StaffGuidesRoutes path={path} />
            <Route path='*'><NotFound /></Route>
        </Switch>
    </Route>
}
