import { useState } from "react";
import * as yup from "yup";
import { Box, IconButton } from "@mui/material";
import { Transition } from "react-spring/renderprops";
import CloseIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/Add';
import { FormHelperText } from "@mui/material";

interface ArrayInputProps {
    attachRef?: (node: any) => void
    disabled?: boolean
    placeholder?: string
    value: any[],
    onChange: (value: any[]) => void
    type?: string

    /**
     * Limit the number of items that can be added.
     */
    limit?: number
}

const ENTER_KEY = "Enter";

export const ArrayInput: React.FC<ArrayInputProps> =
    ({ attachRef, disabled, placeholder, value, type, onChange, limit }) => {
        const [currentVal, setCurrentVal] = useState('');
        const [error, setError] = useState('');

        const hasExceededLimits = limit !== undefined && value.length >= limit;

        const addItem = (currentVal: string) => (
            currentVal && !value.includes(currentVal) && !hasExceededLimits
            && onChange([...value, currentVal])
        );
        const removeItem = (currentVal: string) =>
            onChange(value.filter((val) => val !== currentVal));

        const onSubmit = async (e: any) => {
            try {
                e.preventDefault();

                if (type === 'email') {
                    await yup.string()
                        .email('Enter a valid email')
                        .validate(currentVal);
                }

                addItem(currentVal);
                setCurrentVal('');
            } catch (e: any) {
                setError(e.message);
            }
        };


        return <>
            <Box
                display='flex'
                flexWrap='wrap'
                borderRadius={'5px'}
                p={1}
                style={{ backgroundColor: '#ececec' }}>

                <Transition items={value}
                    from={{ transform: 'scale(0, 0)', backgroundColor: 'white' }}
                    enter={{ transform: 'scale(1, 1)' }}
                    leave={{ transform: 'scale(0, 0)' }}>
                    {
                        item => props =>
                            <Box display='flex' alignItems='center'
                                p={0.5} m={0.5} fontSize={'0.8rem'}
                                style={props}>
                                <Box mr={1}>{item}</Box>
                                <IconButton
                                    disabled={disabled}
                                    onClick={() => removeItem(item)}
                                    type='button'
                                    size='small'
                                >
                                    <CloseIcon style={{ width: '1rem', height: '1rem' }} />
                                </IconButton>
                            </Box>
                    }
                </Transition>

                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                    onKeyDown={(e) => {
                        if (e.key === ENTER_KEY) {
                            onSubmit(e);
                        }
                    }}
                >
                    <input
                        className='focus-outline-none'
                        disabled={disabled || hasExceededLimits}
                        ref={attachRef}
                        placeholder={placeholder}
                        type={type}
                        value={currentVal}
                        onChange={(e) => {
                            e.preventDefault();
                            setError('');
                            setCurrentVal(e.target.value);
                        }}
                        style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            width: '100%', height: '100%'
                        }} />

                    <IconButton onClick={onSubmit} size='small'>
                        <PlusIcon style={{ width: '1rem', height: '1rem' }} />
                    </IconButton>
                </div>

            </Box>
            <FormHelperText error>{error}</FormHelperText>
        </>
    }
