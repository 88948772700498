import axios, { AxiosResponse } from "axios";
import { useState, useReducer, Reducer, useCallback, useEffect } from "react";
import { useSnackbar, VariantType } from "notistack";
import { RequestState, Action, reducer, ActionTypes } from "./reducer-utils";
import { FormError, Method, UnauthorizedError } from "./types";
import { useForceLogin } from "../state/AppState";

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    (response) => response, (error) => {
        if (!error.response) return Promise.reject(error);

        const message = error.response.data.message || 'An error occurred';

        if (error.response.status === 400)
            return Promise.reject(
                new FormError(message, error.response.data.errors, error.response.data.code));

        if (error.response.status === 401)
            return Promise.reject(new UnauthorizedError());

        return Promise.reject(error);
    });

export const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

export interface ApiProps {
    method: Method,
    route: string,
    data?: any,
    params?: any,
    /**
     * If true, request is executed on component mount
     */
    loadOnMount?: boolean,
    errorMessage?: string | null,
    successMessage?: string | null,
    onError?: (error: Error) => void,
    onSuccess?: (response: AxiosResponse<any>) => void
}


export const useApi = ({
    method, route, data, params,
    errorMessage, successMessage,
    onError, onSuccess, loadOnMount = false
}: ApiProps) => {
    const [hasForced, setHasForced] = useState(false);
    const [state, dispatch] = useReducer<Reducer<RequestState, Action>>(
        reducer, { loading: loadOnMount, data: null, error: null });
    const { enqueueSnackbar } = useSnackbar();
    const forceLogin = useForceLogin();

    const snack = (variant: VariantType, defaultMessage: string, customMessage?: string | null) => {
        if (customMessage === null) return;

        enqueueSnackbar(
            customMessage || defaultMessage,
            { variant }
        );
    };

    const doRequestOnMount = useCallback(() => {
        let isCancelled = false;
        if (!loadOnMount || (state.loading && hasForced)) return;
        if (!hasForced) setHasForced(true);

        dispatch({ type: ActionTypes.REQUEST_START });
        (async () => {
            try {
                const response = await axios.request({
                    method, url: `${BASE_URL}${route}`,
                    data, params, headers: {}
                });

                if (isCancelled) return;

                dispatch({
                    type: ActionTypes.REQUEST_FINISH,
                    payload: {
                        data: response.data.data
                    }
                });

                snack('success', 'Success!', successMessage);
            } catch (error: any) {
                dispatch({
                    type: ActionTypes.REQUEST_FINISH,
                    payload: {
                        data: null, error
                    }
                });
                console.log(error);
                if (error instanceof UnauthorizedError) {
                    snack('error', 'You need to login to perform this action');
                    forceLogin();
                    return;
                }

                snack('error',
                    error.message || 'An unexpected error occured',
                    errorMessage,
                );
            }
        })();

        return () => { isCancelled = true };
    }, [method, route, loadOnMount, hasForced, setHasForced]);

    useEffect(doRequestOnMount, [loadOnMount]);

    const request = useCallback(async (body: any = data, queryParams: any = params) => {
        dispatch({ type: ActionTypes.REQUEST_START });
        try {
            const response = await axios.request({
                method, url: `${BASE_URL}${route}`,
                data: body, params: queryParams, headers: {}
            });

            dispatch({
                type: ActionTypes.REQUEST_FINISH,
                payload: {
                    data: response.data.data
                }
            });

            snack('success', 'Success!', successMessage);
            if (onSuccess) onSuccess(response);

            return response.data;
        } catch (error: any) {
            dispatch({
                type: ActionTypes.REQUEST_FINISH,
                payload: {
                    data: null, error
                }
            });
            console.log(error);
            if (error instanceof UnauthorizedError) {
                snack('error', 'You need to login to perform this action');
                forceLogin();
                return;
            }

            snack('error',
                error.message || 'An unexpected error occured',
                errorMessage
            );
            if (onError) onError(error);
            throw error;
        }
    }, [route]);

    // TODO figure out the implication of clearing ongoing requests
    const clear = useCallback(() =>
        dispatch({ type: ActionTypes.CLEAR }), []);

    return { ...state, clear, request };
}
