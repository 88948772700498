import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { Pages } from './pages';
import { CurrentUserProvider } from './state/AppState';
import './App.css';
import { PageErrorBoundary } from './components/utils';


let theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ }) => ({
          borderRadius: 10,
          textTransform: 'none'
        })
      }
    }
  },
  palette: {
    primary: {
      main: '#ED3237'
    },
    secondary: {
      main: '#002050',
      light: '#2E3E5C',
      dark: '#0B1B35'
    },
    background: {
      default: '#F7F7F8'
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '60px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '56px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '40px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '30px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '20px',
    }
  }
});

theme = createTheme(theme, {
  typography: {
    h1: {
      color: theme.palette.secondary.main
    },
    h2: {
      color: theme.palette.secondary.main
    },
    h3: {
      color: theme.palette.secondary.main
    },
    h4: {
      color: theme.palette.secondary.main
    },
    h5: {
      color: theme.palette.secondary.main
    },
    subtitle1: {
      color: theme.palette.secondary.light
    },
    body2: {
      color: theme.palette.secondary.light
    }
  }
});

theme = responsiveFontSizes(theme);

const Content = () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
      <Router>
        <CurrentUserProvider>
          <PageErrorBoundary>
            <Pages path={'/'} />
          </PageErrorBoundary>
        </CurrentUserProvider>
      </Router>
    </SnackbarProvider>
  </ThemeProvider>
)

function App() {
  return (
    <Content />
  );
}

export default App;
