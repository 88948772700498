import React, { FC, useEffect, useRef } from "react";
import {
  Badge,
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import PaymentsIcon from '@mui/icons-material/Payments';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useCurrentUser } from "../state/AppState";
import DashboardIcon from "../assets/Category.svg";
import JournalsIcon from "../assets/Chart.svg";
import SubscriptionsIcon from "../assets/Ticket.svg";
import InvoicesIcon from "../assets/graph.svg";
import NotificationsIcon from "../assets/Notification.svg";
import GuidesIcon from "../assets/Papers.svg";
import UsersIcon from "../assets/Document.svg";
import PlansIcon from "../assets/Calendar.svg";

const SideNavIcon: FC<{ sx: BoxProps['sx'], color: string, data: string }> = ({ sx, color, data }) => {
  const ref = useRef<HTMLObjectElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!ref.current) return;
      if (!ref.current.contentDocument) return;
      const svgNode = ref.current.contentDocument.getElementsByTagName('svg').item(0);
      if (!svgNode) return;

      const paths = svgNode.getElementsByTagName('path');
      const gs = svgNode.getElementsByTagName('g');

      svgNode.setAttribute("fill", color);
      for (let index = 0; index < paths.length; index++) {
        const path = paths.item(index);
        path?.setAttribute("fill", color);
      }

      for (let index = 0; index < gs.length; index++) {
        const g = gs.item(index);
        g?.setAttribute('opacity', '1');
      }

      clearInterval(interval);
    }, 10);
  }, [sx]);

  return <Box
    ref={ref}
    component='object'
    data={data}
    type="image/svg+xml"
    sx={sx}
  >
  </Box>;
}

export const SideNavItems: React.FC<{ open: boolean, selected: string }> = ({ open, selected }) => {
  const { user, ready } = useCurrentUser();

  if (!ready) return <></>;

  const defaultColor = "#9A9AA9";
  const selectedColor = "#002050";
  const styles = (nav: string) => ({
    listItemBtn: {
      position: 'relative',
      minHeight: 48,
      justifyContent: open ? 'initial' : 'center',
      px: 2.5,
      color: nav === selected ? selectedColor : defaultColor,
      '&:before': {
        content: '""',
        position: 'absolute',
        opacity: 0.2,
        top: 0,
        bottom: 0,
        left: 0,
        width: '45px',
        background: nav === selected
          ? 'linear-gradient(90deg, #002050 0%, rgba(172, 169, 255, 0.00) 91.25%)'
          : 'none',
      }
    },
    text: {
      opacity: open ? 1 : 0,
      fontWeight: 500,
    },
    icon: {
      width: '21px',
      height: '21px',
    },
    iconColor: nav === selected ? selectedColor : defaultColor
  });

  return <>
    <List>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          component={RouterLink}
          to={"/dashboard"}
          sx={{
            ...styles('dashboard').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={DashboardIcon}
              sx={{ ...styles('dashboard').icon }}
              color={styles('dashboard').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} sx={{ ...styles('dashboard').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          component={RouterLink}
          to={"/notifications"}
          sx={{
            ...styles('notifications').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {/* <Badge color='primary'
              invisible={user?.latestNotifications.length == 0}
              variant='dot'
            > */}
              <SideNavIcon
                data={NotificationsIcon}
                sx={{ ...styles('notifications').icon }}
                color={styles('notifications').iconColor}
              />
            {/* </Badge> */}
          </ListItemIcon>
          <ListItemText primary={"Notifications"} sx={{ ...styles('notifications').text }} />
        </ListItemButton>
      </ListItem>

      {
        user?.isCompany && <>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              component={RouterLink}
              to={"/members"}
              sx={{
                ...styles('members').listItemBtn
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PeopleAltIcon
                  sx={{
                    ...styles('members').icon,
                    color: styles('members').iconColor
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Members"} sx={{ ...styles('members').text }} />
            </ListItemButton>
          </ListItem>
        </>
      }

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.subscribe ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/subscriptions/current"}
          sx={{
            ...styles('payments').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <PaymentsIcon
              sx={{
                ...styles('payments').icon,
                color: styles('payments').iconColor
              }}
            />
          </ListItemIcon>
          <ListItemText primary={"Payments"} sx={{ ...styles('payments').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.marks ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/journals"}
          sx={{
            ...styles('journals').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={JournalsIcon}
              sx={{ ...styles('journals').icon }}
              color={styles('journals').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Journals"} sx={{ ...styles('journals').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.users ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/users"}
          sx={{
            ...styles('users').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={UsersIcon}
              sx={{ ...styles('users').icon }}
              color={styles('users').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Users"} sx={{ ...styles('users').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.logs ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/logs"}
          sx={{
            ...styles('logs').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <AutoGraphIcon
              sx={{
                ...styles('logs').icon,
                color: styles('logs').iconColor
              }}
            />
          </ListItemIcon>
          <ListItemText primary={"Logs"} sx={{ ...styles('logs').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.plans ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/plans"}
          sx={{
            ...styles('plans').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={PlansIcon}
              sx={{ ...styles('plans').icon }}
              color={styles('plans').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Plans"} sx={{ ...styles('plans').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.subscriptions ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/subscriptions/all"}
          sx={{
            ...styles('subscriptions').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={SubscriptionsIcon}
              sx={{ ...styles('subscriptions').icon }}
              color={styles('subscriptions').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Subscriptions"} sx={{ ...styles('subscription').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.invoices ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={"/invoices/all"}
          sx={{
            ...styles('invoices').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={InvoicesIcon}
              sx={{ ...styles('invoices').icon }}
              color={styles('invoices').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Invoices"} sx={{ ...styles('invoices').text }} />
        </ListItemButton>
      </ListItem>

      <ListItem
        disablePadding
        sx={{
          display: user?.permissions?.guides ? 'block' : 'none'
        }}
      >
        <ListItemButton
          component={RouterLink}
          to={`/${user?.permissions?.guides}-guides`}
          sx={{
            ...styles('guides').listItemBtn
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <SideNavIcon
              data={GuidesIcon}
              sx={{ ...styles('guides').icon }}
              color={styles('guides').iconColor}
            />
          </ListItemIcon>
          <ListItemText primary={"Guides"} sx={{ ...styles('guides').text }} />
        </ListItemButton>
      </ListItem>

    </List>
  </>
}
