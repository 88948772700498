import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { UserHeader } from '../../components/headers';
import { Footer } from '../../components/Footer';
import { ResponsiveSearchBar } from '../../components/search';

export const SearchHome: React.FC = () => {
  const theme = useTheme();
  return <>
    <Box>
      <UserHeader />

      <Box sx={{ py: '80px' }}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >

          <Typography
            variant='h1'
            sx={{
              background: "linear-gradient(136deg, #ED3237 0%, #93119E 51.04%, #002050 81.25%)",
              backgroundClip: 'text',
              color: 'transparent',
              fontWeight: 800,
              textTransform: 'uppercase',
              mb: 2,
              [theme.breakpoints.up('md')]: {
                fontSize: "80px",
              }
            }}
          >
            Jeetraka Search
          </Typography>

          <Typography
            variant='h2'
            sx={{
              fontSize: '2rem',
              [theme.breakpoints.up('sm')]: {
                fontSize: "2.5rem",
              }
            }}
          >
            Over 30years of Nigerian Trademark Records!
          </Typography>
          <Typography
            variant='h3'
            sx={{
              fontWeight: 400,
              color: 'black',
              mt: 2, mb: 4,
              fontSize: '1rem',
              [theme.breakpoints.up('sm')]: {
                fontSize: "1.5rem",
              }
            }}
          >
            Do more with extended searches and fine-tune your search results
          </Typography>

          <ResponsiveSearchBar />

        </Container>
      </Box>

      <Footer />
    </Box>
  </>
}
