import { FC, lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const LogsPage = lazy(() => import("./Logs"));

export const Logs: FC<Pick<RouteProps, 'path'>> = ({ path }) => {
  const { user } = useCurrentUser(true);

  return <Route path={path}>
      <Switch>
          {
              user?.permissions?.logs &&
              <Route path={path} component={LogsPage} />
          }
          <Route path='*'><NotFound /></Route>
      </Switch>
  </Route>
}
