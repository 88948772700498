import clsx from 'clsx';
import {
    Box,
    Hidden,
    Paper,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Theme
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import { HideableTableCell } from './Limiters';
import { MiniSearchResult, SearchResult } from './SearchResult';
import { TableContainer } from '@mui/material';


export interface SearchResultsProps {
    className?: string
    loading?: boolean
    data?: any[],
    limits?: string[]
}

export const SearchResults: React.FC<SearchResultsProps> =
    ({ className, data = [], limits = [], loading }) => {
        const classes = useStyles();

        const limitedFields = limits.reduce(
            (prev: any, field: string) => ({ ...prev, [field]: true }),
            {}
        );

        return <Box>
            <Hidden mdUp>
                <Box maxWidth={'40rem'} mx='auto'>
                    {
                        loading
                            ?
                            [0, 1, 2, 3, 4].map((index) => (
                                <MiniSearchResult key={index}
                                    loading={loading} />
                            ))
                            : data.map((item, index) => (
                                <MiniSearchResult
                                    key={index}
                                    data={item}
                                    limitedFields={limitedFields} />
                            ))
                    }
                </Box>
            </Hidden>

            <Hidden smDown>
                <TableContainer
                    component={Paper}
                    style={{
                        minHeight: '20rem',
                        height: '50vh'
                    }}
                >
                    <Table stickyHeader className={clsx(classes.table, className)}>
                        <TableHead className='uppercase'>
                            <TableRow>
                                <TableCell />

                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['caseNumber']}
                                >
                                    Case number
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['trademark']}
                                >
                                    Trademark
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['device']}
                                >
                                    Device
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['applicationDate']}
                                >
                                    Application Date
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['class']}
                                >
                                    Class
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['applicationNumber']}
                                    style={{
                                        minWidth: '8rem'
                                    }}
                                >
                                    Application Number
                                </HideableTableCell>
                                <HideableTableCell
                                    align='center'
                                    hide={limitedFields['proprietor']}
                                >
                                    Proprietor
                                </HideableTableCell>
                            </TableRow>
                        </TableHead>


                        <TableBody>
                            {
                                loading
                                    ? [0, 1, 2, 3, 4].map((index) => (
                                        <SearchResult key={index}
                                            loading={loading} />
                                    ))
                                    : data.map((item, index) => (
                                        <SearchResult
                                            key={index}
                                            data={item}
                                            limitedFields={limitedFields} />
                                    ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>
        </Box>
    }


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            maxWidth: '80rem',
            margin: '0 auto',

            '& th': {
                backgroundColor: 'inherit'
            },

            [theme.breakpoints.up('sm')]: {
                minWidth: '40rem',
            }
        }
    })
);
