import { FC, ReactNode, useRef, useState } from 'react';
import { IconButton, Popover } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';


interface MorePopoverProps {
    children: (setOpen: (open: boolean) => void) => ReactNode
}

export const MorePopover: FC<MorePopoverProps> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef(null);

    return <>
        <div ref={buttonRef}>
            <IconButton onClick={() => setOpen(o => !o)}>
                <MoreIcon style={{ width: '1rem', height: '1rem' }} />
            </IconButton>
        </div>

        <Popover
            anchorEl={buttonRef.current}
            open={open}
            onClose={() => setOpen(false)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            {children(setOpen)}
        </Popover>
    </>
}
