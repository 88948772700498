import { FC, useEffect } from "react";
import { PictureAsPdf } from "@mui/icons-material";
import { usePDF, Document, Link, Image, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { SpinnerButton } from "../forms";
import { formatDate } from "../../helpers";
import LogoLight from '../../assets/jeetraka_logo_light.png';

const A4 = {
    width: 595,
    height: 842
};

const padding = {
    padX: 20,
    padY: 20
};

const columns: { header: any, grow: number, value: (row: any) => any }[] = [
    // {
    //     header: <Text>Journal Name</Text>,
    //     grow: 1,
    //     value: (row) => <Text>{row.journalName}</Text>
    // },
    {
        header: <Text>Journal Date</Text>,
        grow: 1,
        value: (row) => <Text>{formatDate(row.journalDate)}</Text>
    },
    {
        header: <Text>Application Number</Text>,
        grow: 1.5,
        value: (row) => <Text>{row.applicationNumber}</Text>
    },
    {
        header: <Text>Trademark</Text>,
        grow: 1,
        value: (row) => <Text>{row.trademark}</Text>
    },
    {
        header: <Text>Device</Text>,
        grow: 1,
        value: (row) => row.device && <Image src={row.device} />
    },
    {
        header: <Text>Class</Text>,
        grow: 1,
        value: (row) => <Text>Class {row.class}</Text>
    },
    {
        header: <Text>Proprietor</Text>,
        grow: 1,
        value: (row) => <Text>{row.proprietor}</Text>
    },
    // {
    //     header: <Text>Status</Text>,
    //     grow: 1,
    //     value: (row) => <Text>{row.status}</Text>
    // },
];

const NUM_ROWS = 10;
const HEADER_HEIGHT = 25;
const LOGO_WIDTH = 146;
const LOGO_HEIGHT = 50;
const TITLE_HEADER_HEIGHT = 60;
const PAGE_NUMBER_HEIGHT = 22;
const UNIT_ROW_HEIGHT = ((A4.height - padding.padY) - (HEADER_HEIGHT + 2) - PAGE_NUMBER_HEIGHT - TITLE_HEADER_HEIGHT) / NUM_ROWS;
const UNIT_COL_WIDTH = (A4.width - padding.padX) / columns.length;

const styles = StyleSheet.create({
    page: {
        paddingLeft: padding.padX / 2,
        paddingRight: padding.padX / 2,
        paddingTop: TITLE_HEADER_HEIGHT + padding.padY / 2,
        paddingBottom: padding.padY / 2,
        fontSize: 12
    },
    titleHeader: {
        position: 'absolute',
        top: TITLE_HEADER_HEIGHT - LOGO_HEIGHT,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    col: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderRight: '1px',
        borderBottom: '1px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 2px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        height: UNIT_ROW_HEIGHT
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: PAGE_NUMBER_HEIGHT - 12,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

const SearchResultsDoc: FC<{ data: any[] }> = ({ data }) => {
    const dataPages = [];
    while (dataPages.length < data.length / NUM_ROWS)
        dataPages.push(data.slice(
            dataPages.length * NUM_ROWS,
            dataPages.length * NUM_ROWS + NUM_ROWS
        ));

    return (
        <Document>
            {
                dataPages.map((dataPage, pageIndex) => (
                    <Page key={pageIndex} size="A4" style={styles.page}>
                        <View style={styles.titleHeader}>
                            <Link src="www.jeetraka.com">
                                <Image
                                    src={LogoLight}
                                    style={{
                                        height: LOGO_HEIGHT,
                                        width: LOGO_WIDTH,
                                        objectFit: 'contain'
                                    }}
                                />
                            </Link>
                        </View>

                        <View
                            style={[styles.row, { height: HEADER_HEIGHT }]}
                        >
                            {
                                columns.map((col, index) => (
                                    <View key={index}
                                        style={[
                                            styles.col,
                                            {
                                                borderTop: '1px',
                                                borderLeft: index === 0 ? 1 : 0,
                                                width: col.grow * UNIT_COL_WIDTH
                                            }
                                        ]}>
                                        {col.header}
                                    </View>
                                ))
                            }
                        </View>

                        {
                            dataPage.map((row, rowIndex) => (
                                <View
                                    key={rowIndex}
                                    style={[styles.row, { height: UNIT_ROW_HEIGHT }]}
                                >
                                    {
                                        columns.map((col, index) => (
                                            <View key={index}
                                                style={[
                                                    styles.col,
                                                    {
                                                        borderLeft: index === 0 ? 1 : 0,
                                                        width: col.grow * UNIT_COL_WIDTH
                                                    }
                                                ]}>
                                                {col.value(row)}
                                            </View>
                                        ))
                                    }
                                </View>
                            ))
                        }

                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }: any) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                    </Page>
                ))
            }
        </Document>
    );
};

export const ExportSearchResultsButton: FC<{ results: any[] }> = ({ results }) => {
    const document = <SearchResultsDoc data={results} />;
    const [instance, updateInstance] = usePDF({ document });
    useEffect(() => {
        updateInstance();
    }, [results])

    return <a
        href={instance.url ?? undefined}
        download="results.pdf"
        style={{ textDecoration: 'none' }}
    >
        <SpinnerButton
            loading={instance.loading}
            startIcon={<PictureAsPdf />}
            size={'small'}
        >
            Export Current Page
        </SpinnerButton>
    </a>
}
