import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import RegistrationCompleteImage from '../../assets/registration_complete.svg';

export const RegistrationComplete: React.FC = () => {
  return <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: 4, py: 8,
        backgroundColor: 'white',
        width: '100%',
        maxWidth: '500px',
        maxHeight: '500px'
      }}
    >
      <Box component='img' alt='thumbs up and celebration' src={RegistrationCompleteImage} />
      <Typography sx={{ mt: 2, mb: 6, fontWeight: 500 }}>
        Your account has been successfully created.
      </Typography>

      <Button
        component={Link}
        to='/auth/login'
        variant='contained'
        color='primary'
        sx={{
          width: '170px', height: '50px'
        }}
      >
        Go to Login
      </Button>
    </Box>
  </>
}
