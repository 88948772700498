import { lazy } from "react";
import { Route, RouteProps, Switch } from 'react-router-dom';
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const AllSubscriptions = lazy(() => import("./AllSubscriptions"));
const CurrentUserSubscription = lazy(() => import('./CurrentUserSubscription'));
const SingleSubscription = lazy(() => import('./SingleSubscription'));
const Subscribe = lazy(() => import('./Subscribe'));
const SubscriptionPage = lazy(() => import('./Subscriptions'));


export const Subscriptions: React.FC<Pick<RouteProps, 'path'>> =
    ({ path }) => {
        const { user } = useCurrentUser();

        return <Route path={path}>
            <Switch>
                <Route path={`${path}/`} component={SubscriptionPage} exact />
                {
                    user?.permissions?.subscriptions &&
                    <Route path={`${path}/all`} component={AllSubscriptions} exact />
                }
                <Route path={`${path}/current`} component={CurrentUserSubscription} exact />
                <Route path={`${path}/id/:subscriptionId`} component={SingleSubscription} exact />
                {
                    ((user && user.permissions?.subscribe) || !user) &&
                    <Route path={`${path}/:planId/pay`} component={Subscribe} exact />
                }
                <Route path='*'><NotFound /></Route>
            </Switch>
        </Route>
    }
