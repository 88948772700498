import React from "react";
import { Transition } from 'react-spring/renderprops';
import { Route, RouteProps, matchPath, useLocation } from 'react-router-dom';

type TransitionProps = Omit<RouteProps, 'path'> & { path: string, children: any };

export const TransitionRoute: React.FC<TransitionProps> = ({ children, ...props }) => {
    const location = useLocation();
    const match = matchPath(location.pathname, [props.path]);
    const show = match ? true : false;

    return <Route>
        <Transition
            items={show}
            from={{ transform: "translate(100vw, 0)", opacity: 0 }}
            enter={{ transform: "translate(0, 0)", opacity: 1 }}
            leave={{
                position: 'absolute',
                transform: "translate(-100vw, 0)",
                opacity: 0 } as React.CSSProperties}>
            {show => show && (props => <div style={props}>{children}</div>)}
        </Transition>
    </Route>
}
