import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  Link as MuiLink,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import DropdownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useHistory } from 'react-router-dom';
import { NotificationMenu } from '../../pages/notifications/NotificationMenu';
import { useCurrentUser } from '../../state/AppState';
import { useLogout } from '../../api';
import { CollapsibleNav } from './CollapsibleNav';
import { Nav } from './Nav';
import { useStyles } from './style';
import LogoLight from '../../assets/jeetraka_logo_light.svg';


export const UserHeader: React.FC<{ lgMaxWidth?: string }> = ({ lgMaxWidth }) => {
  const { user, ready } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openContainer, setOpenContainer] = useState(null);
  const [closeContainer, setCloseContainer] = useState(null);
  const { logout } = useLogout();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const onMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemRoute = (route: string) => {
    return () => {
      history.push(route);
      onMenuClose();
    }
  }

  const onMenuItemClick = (onClick: () => void) => {
    return () => {
      onClick();
      onMenuClose();
    }
  }

  const navOpenRef = useCallback((node: any) => {
    if (node !== null) {
      setOpenContainer(node);
    }
  }, []);

  const navCloseRef = useCallback((node: any) => {
    if (node !== null) {
      setCloseContainer(node);
    }
  }, []);

  return <Box
    position='relative'
    component={'header'}
    py={2} width={'100%'}
    zIndex={theme.zIndex.appBar}
    style={{ backgroundColor: 'white' }}>
    <Container
      sx={{
        [theme.breakpoints.up('lg')]: { maxWidth: lgMaxWidth || 'lg' },
      }}
    >
      <Box
        display='flex'
        alignItems={'center'}
        width={'100%'}
      >

        <CollapsibleNav
          classes={classes}
          closeContainer={closeContainer}
          openContainer={openContainer}>

          <Nav to='/'>Home</Nav>
          <Nav to='/search'>Search</Nav>
          <Nav to='/subscriptions'>Pricing</Nav>

          {
            (user?.permissions?.support || !user) &&
            <Nav to='/contact-us'>Contact Us</Nav>
          }

          {
            ready && !user && (
              <Hidden smUp>
                <Nav to='/auth/login'>Login</Nav>
                <Nav to='/auth/signup'>Signup</Nav>
              </Hidden>
            )
          }

        </CollapsibleNav>

        <MuiLink
          component={Link}
          color='inherit'
          to='/'
          sx={{ mr: 'auto' }}
        >
          <img style={{ width: '146px', height: '50px' }} src={LogoLight} />
        </MuiLink>

        <div className={classes.openContainer} ref={navOpenRef}></div>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {
            ready && user && (<>
              <NotificationMenu />

              <Box
                display='flex'
                alignItems='center'
                aria-haspopup="true"
                onClick={onMenuOpen}
                style={{ cursor: 'pointer' }}
              >
                <ProfileIcon />
                <DropdownIcon />
              </Box>
            </>)
          }

          {
            ready && !user && (
              <Box sx={{
                display: 'none',
                alignItems: 'center',
                [theme.breakpoints.up('sm')]: { display: 'flex' }
              }}>
                <Nav to='/auth/login'>Login</Nav>

                <Button
                  to='/auth/signup'
                  component={Link}
                  variant={'contained'}
                  sx={{ ml: 2, width: '120px', height: '50px' }}
                >
                  Sign up
                </Button>
              </Box>
            )
          }

        </Box>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          anchorReference={'anchorEl'}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onMenuClose}
        >
          <MenuItem onClick={onMenuItemRoute('/dashboard')}>Dashboard</MenuItem>

          <Divider />

          <MenuItem onClick={onMenuItemRoute('/profile')}>Your Profile</MenuItem>

          {
            user?.permissions?.subscribe &&
            <MenuItem onClick={onMenuItemRoute('/subscriptions/current')}>Payments & Subscription</MenuItem>
          }

          {
            user?.isCompany && <MenuItem onClick={onMenuItemRoute('/members')}>
              Your Members</MenuItem>
          }

          <Divider />

          <MenuItem onClick={onMenuItemClick(logout)}>Logout</MenuItem>
        </Menu>

      </Box>

      <div ref={navCloseRef}></div>
    </Container>
  </Box>;
}
