import { useState } from 'react';
import {
    Button,
    Collapse,
    Hidden,
    IconButton,
    Paper,
    TableCell,
    TableRow,
    Theme,
    Typography,
    Divider,
    Box
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles'; 
import { Skeleton } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDate } from '../../helpers';
import { FadeTransition } from '../animations';
import { HideableBox, HideableTableCell } from './Limiters';


export interface SearchResultProps {
    loading?: boolean
    data?: any,
    limitedFields?: any
    style?: React.CSSProperties
}

export const MiniSearchResult: React.FC<SearchResultProps> =
    ({ loading, data, limitedFields, style }) => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);

        const image = (height: string, width: string) => (
            !loading ?
                (
                    data?.device
                        ? <img src={data.device}
                            alt={'device'}
                            style={{
                                width,
                                height,
                                objectFit: 'contain'
                            }} />
                        : <div style={{
                            width, height,
                            backgroundColor: '#eeee'
                        }}>
                            <Typography align='center' style={{
                                paddingTop: '40%',
                                color: '#aaaa',
                                fontWeight: 'bold'
                            }}>
                                No Device
                            </Typography>
                        </div>
                )
                :
                <Skeleton style={{ transform: 'scale(1, 1)' }}
                    width={width} height={height} />

        );

        const get = (name: string, formatter?: (value: any) => string) => (
            <Typography variant='body2' style={{ fontSize: '0.75rem' }}>
                {
                    formatter
                        ? formatter(data?.[name])
                        : data?.[name] || '______'
                }
            </Typography>
        );

        return <Paper variant='outlined' className={classes.smallContentRoot} style={style}>
            <div className={classes.smallContentHeader}>

                {image('7em', '6em')}

                <div style={{
                    marginLeft: '1rem',
                    flex: 1
                }}>

                    <HideableBox
                        mb='0.5rem'
                        hide={limitedFields?.['caseNumber']}
                    >
                        <Typography variant='caption'
                            style={{ fontSize: '0.675rem' }}>
                            Case Number
                        </Typography>
                        {get('caseNumber')}
                    </HideableBox>

                    <HideableBox mb='0.5rem'
                        hide={limitedFields?.['trademark']}>
                        <Typography variant='caption'
                            style={{ fontSize: '0.675rem' }}>
                            Trademark
                        </Typography>
                        {get('trademark')}
                    </HideableBox>

                </div>

                <FadeTransition
                    show={data}
                    style={{
                        position: 'absolute',
                        bottom: -10,
                        right: 0,
                        width: 40,
                        height: 40
                    }}
                    items={[
                        <IconButton
                            aria-label="expand"
                            size="small"
                            onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    ]}
                />

            </div>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <Box pt={2}>
                    <Divider />
                    <Box pt={2}>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['applicationDate']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Application Date
                            </Typography>
                            {get('applicationDate', formatDate)}
                        </HideableBox>

                        <HideableBox hide={limitedFields?.['class']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Class
                            </Typography>
                            {get('class')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['applicationNumber']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Application Number
                            </Typography>
                            {get('applicationNumber')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['proprietor']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Proprietor
                            </Typography>
                            {get('proprietor')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['goodsSpecification']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Specification of Goods
                            </Typography>
                            {get('goodsSpecification')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['journalName']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Journal Name
                            </Typography>
                            {get('journalName')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['journalDate']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Journal Date
                            </Typography>
                            {get('journalDate', formatDate)}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['volumeNumber']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Volume Number
                            </Typography>
                            {get('volumeNumber', (value) => `VOL. ${value}`)}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['journalNumber']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Journal Number
                            </Typography>
                            {get('journalNumber', (value) => `NO. ${value}`)}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['pageNumber']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Journal Page
                            </Typography>
                            {get('pageNumber', (value) => `Page ${value}`)}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['publisher']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Publisher
                            </Typography>
                            {get('publisher')}
                        </HideableBox>

                        <HideableBox mb='0.5rem'
                            hide={limitedFields?.['country']}>
                            <Typography variant='caption'
                                style={{ fontSize: '0.675rem' }}>
                                Country
                            </Typography>
                            {get('country')}
                        </HideableBox>

                    </Box>
                </Box>
            </Collapse>

        </Paper>
    }

export const SearchResult: React.FC<SearchResultProps> =
    ({ loading, data, limitedFields, style }) => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);

        const image = (height: string, width: string) => (
            !loading ?
                (
                    data?.device
                        ? <img src={data.device}
                            alt={'device'}
                            style={{
                                width,
                                height,
                                objectFit: 'contain'
                            }} />
                        : <div style={{
                            width, height,
                            backgroundColor: '#eeee'
                        }}>
                            <Typography align='center' style={{
                                paddingTop: '40%',
                                color: '#aaaa',
                                fontWeight: 'bold'
                            }}>
                                No Device
                            </Typography>
                        </div>
                )
                :
                <Skeleton style={{ transform: 'scale(1, 1)' }}
                    width={width} height={height} />

        );

        const get = (name: string, formatter?: (value: any) => string) => (
            <Typography variant='body2' style={{ fontSize: '0.75rem' }}>
                {
                    formatter
                        ? formatter(data?.[name])
                        : data?.[name] || '______'
                }
            </Typography>
        );

        return <>
            <TableRow style={style}>
                {
                    loading
                        ? <>
                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>

                            <TableCell>
                                <Skeleton />
                            </TableCell>
                        </>
                        : <>
                            <TableCell>
                                <Button
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                    endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                >
                                    {open ? 'Hide' : 'View More'}
                                </Button>
                            </TableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['caseNumber']}
                            >
                                {get('caseNumber')}
                            </HideableTableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['trademark']}
                            >
                                {get('trademark')}
                            </HideableTableCell>

                            <HideableTableCell
                                align='left'
                                style={{ width: '6em' }}
                                hide={limitedFields?.['device']}
                            >
                                {image('6em', '6em')}
                            </HideableTableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['applicationDate']}
                            >
                                {get('applicationDate', formatDate)}
                            </HideableTableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['class']}
                            >
                                {get('class')}
                            </HideableTableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['applicationNumber']}
                            >
                                {get('applicationNumber')}
                            </HideableTableCell>

                            <HideableTableCell
                                align='center'
                                hide={limitedFields?.['proprietor']}
                            >
                                {get('proprietor')}
                            </HideableTableCell>
                        </>
                }
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            display='flex'
                            flexWrap='wrap'
                            margin={2}
                        >
                            <HideableBox mb={2}
                                width='100%'
                                hide={limitedFields?.['goodsSpecification']}
                            >
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Specification of Goods
                                </Typography>

                                <Typography>
                                    {data?.goodsSpecification || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2}
                                width='15%'
                                hide={limitedFields?.['journalName']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Journal Name
                                </Typography>
                                <Typography>
                                    {data?.journalName || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2}
                                width="15%"
                                hide={limitedFields?.['journalDate']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Journal Date
                                </Typography>
                                <Typography>
                                    {data?.journalDate && formatDate(data?.journalDate) || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2} width="15%"
                                hide={limitedFields?.['volumeNumber']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Volume Number
                                </Typography>
                                <Typography>
                                    {data?.volumeNumber && `VOL. ${data?.volumeNumber}` || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2} width="15%"
                                hide={limitedFields?.['journalNumber']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Journal Number
                                </Typography>
                                <Typography>
                                    {data?.journalNumber && `NO. ${data?.journalNumber}` || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2} width="15%"
                                hide={limitedFields?.['pageNumber']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Journal Page
                                </Typography>
                                <Typography>
                                    {data?.pageNumber && `Page ${data?.pageNumber}` || '___'}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb={2} width='100%'
                                hide={limitedFields?.['publisher']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Publisher
                                </Typography>
                                <Typography>
                                    {data?.publisher}
                                </Typography>
                            </HideableBox>

                            <HideableBox mb='0.5rem'
                                hide={limitedFields?.['country']}>
                                <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                                    Country
                                </Typography>
                                <Typography>
                                    {data?.country}
                                </Typography>
                            </HideableBox>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        smallContentRoot: {
            fontSize: '1rem',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            paddingLeft: theme.spacing(), paddingRight: theme.spacing(),
            paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2)
        },
        smallContentHeader: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
        }
    })
);
