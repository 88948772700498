import { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import { Collapse, Hidden, IconButton, Portal } from '@mui/material';
import { Transition } from 'react-spring/renderprops';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


export interface CollapsibleNavProps {
    className?: string
    children: any
    classes?: { burger?: string, hidden?: string, open?: string }
    closeContainer?: HTMLElement | null
    openContainer?: HTMLElement | null
}

export const CollapsibleNav: React.FC<CollapsibleNavProps> =
    ({ children, classes, closeContainer, openContainer }) => {
        const [isOpen, setIsOpen] = useState<boolean>(false);

        return <>

            <Portal container={openContainer}>
                <div className={clsx(classes?.open)}>{children}</div>
            </Portal>

            <Hidden mdUp>
                <IconButton className={clsx(classes?.burger)} aria-label='nav' onClick={() => setIsOpen(o => !o)}>
                    <Transition items={isOpen}
                        from={{ transform: 'scale(0, 0)' }}
                        enter={{ position: 'absolute', transform: 'scale(1, 1)' } as CSSProperties}
                        leave={{ transform: 'scale(0, 0)' }}
                    >
                        {
                            items => items
                                ? (props => <CloseIcon style={props} />)
                                : (props => <MenuIcon style={props} />)
                        }
                    </Transition>
                </IconButton>
            </Hidden>


            <Portal container={closeContainer}>
                <Hidden mdUp>
                    <Collapse in={isOpen} timeout='auto' unmountOnExit>
                        <div className={clsx(classes?.hidden)}>{children}</div>
                    </Collapse>
                </Hidden>
            </Portal>
        </>
    }
