import { useEffect } from 'react';
import {
  Breadcrumbs,
  Button,
  Box,
  Container,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Amount } from '../../components/display/Amount';
import { useGetInvoices, useGetUsers } from '../../api';
import { Actions } from './invoice-actions';
import { SideNav } from '../../components/SideNav';

export const Invoice = () => {
  const theme = useTheme();
  const { invoiceId } = useParams() as { invoiceId: string };
  const { data, request, clear } = useGetInvoices({ code: invoiceId }, true);
  const { data: users, request: getUser } = useGetUsers(undefined, false);
  const invoice = data?.results[0];
  const user = users?.results[0];

  const total = invoice?.items?.reduce(
    (acc: number, item: any) => acc + (item.quantity * item.unitCost),
    0
  ) ?? 0;

  useEffect(() => {
    if (
      !data?.results?.[0] ||
      data.results[0].userId == users?.results[0]._id
    ) return;
    getUser(null, { _id: data.results[0].userId });
  }, [data, users]);

  return <>
    <Box
      minHeight='100vh'
      py={4}
      bgcolor={theme.palette.background.default}
      style={{ overflowX: 'hidden' }}
      display='flex'
    >
      <SideNav selected='invoices' />

      <Container>

        <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 4 }}>
          <Link color="inherit" component={RouterLink} to="/invoices/all">
            All Invoices
          </Link>
          <Typography color="textPrimary">{invoiceId}</Typography>
        </Breadcrumbs>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              border: '1px solid black',
              width: '1rem',
              height: '1rem',
              backgroundColor: {
                'PENDING': '#ff8c00',
                'FULFILLED': 'green',
                'CANCELED': 'red'
              }[invoice?.status.status as 'PENDING' | 'FULFILLED' | 'CANCELED'],
              mr: 1
            }}
          ></Box>

          <Typography variant='h6' sx={{ mr: 'auto' }}>
            INVOICE ID: {invoiceId}
          </Typography>

          {
            invoice
              ? <Box sx={{ my: 1 }}>
                <Actions
                  id={invoice._id}
                  status={invoice.status.status}
                  onChange={() => {
                    clear();
                    request();
                  }}
                />
              </Box>
              : <></>
          }
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            columnGap: 10,
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              flexGrow: 2,
              my: 2,
              minWidth: 300
            }}
          >
            <Typography variant='caption'>Customer</Typography>
            <Typography variant='h5'>
              {
                user
                  ? user.companyName || `${user.firstName} ${user.lastName}`
                  : <>-- --</>
              }
            </Typography>

            <Typography variant='h6' sx={{ mt: 4, mb: 1 }}>Items</Typography>

            {
              invoice?.items?.map((item: any, index: number) =>
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Typography sx={{ mr: 'auto' }}>{item.planName}</Typography>
                  <Typography>x{item.quantity}</Typography>
                </Box>
              )
            }

            <Typography sx={{ mt: 4 }}>
              Total: <Amount amount={total} currency={invoice?.currency ?? ''} sign />
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              my: 2
            }}
          >
            <Typography sx={{ mb: 2 }} variant='h6'>HISTORY</Typography>

            {
              invoice?.history?.map((status: any, index: number) =>
                <Box
                  key={index}
                  sx={{
                    mb: 1
                  }}
                >
                  <Typography variant='caption'>
                    {new Date(status.timestamp).toDateString()}
                  </Typography>
                  <Typography>{status.status}</Typography>
                </Box>
              )
            }
          </Box>
        </Box>

      </Container>
    </Box>
  </>;
}
