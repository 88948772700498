import React from 'react';
import {
  Box,
  CSSObject,
  Drawer as MuiDrawer,
  IconButton,
  Link as MuiLink,
  List,
  styled,
  Theme,
  useTheme,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { SideNavItems } from './SideNavItems';
import LogoLight from '../assets/jeetraka_logo_light.svg';
import LogoutIcon from '../assets/LogoutGray.svg';
import { useLogout } from '../api';
import { useCurrentUser } from '../state/AppState';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  marginBottom: theme.spacing(4),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  paddingTop: theme.spacing(3)
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SideNav: React.FC<{ selected: string }> = ({ selected }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user } = useCurrentUser();
  const { logout } = useLogout();

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return <>
    <Box>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {
            open &&
            <MuiLink
              component={Link}
              color='inherit'
              to='/'
              sx={{ mr: 2 }}
            >
              <img style={{ width: '146px', height: '50px' }} src={LogoLight} />
            </MuiLink>
          }

          <IconButton onClick={handleToggleDrawer}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <List>
          <SideNavItems open={open} selected={selected} />
        </List>

        <List
          sx={{
            mt: 'auto'
          }}
        >
          {
            open
              ? <>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={Link}
                    to='/profile'
                    sx={{ px: 2.5 }}
                  >
                    <ListItemIcon sx={{ minWidth: '45px' }}>
                      <AccountBoxIcon sx={{ height: '40px', width: '40px' }} />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        user?.isCompany
                          ? user?.companyName
                          : `${user?.firstName} ${user?.lastName}`
                      }
                      secondary={user?.email}

                    />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => logout()}
                    sx={{ px: 2.5 }}
                  >
                    <ListItemIcon sx={{ pl: '6px' }}>
                      <Box
                        component={'img'}
                        src={LogoutIcon}
                        alt='exit'
                        sx={{
                          width: '21px',
                          height: '21px'
                        }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </>
              : <>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={Link}
                    to='/profile'
                    sx={{ px: 1.25 }}
                  >
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <AccountBoxIcon sx={{ height: '40px', width: '40px' }} />
                    </ListItemIcon>
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => logout()}
                    sx={{ px: 2.5 }}
                  >
                    <ListItemIcon>
                      <Box
                        component={'img'}
                        src={LogoutIcon}
                        alt='exit'
                        sx={{
                          width: '21px',
                          height: '21px'
                        }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </>
          }
        </List>
      </Drawer>
    </Box>
  </>
}
