import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonProps, IconButtonProps } from '@mui/material';
import { Transition } from 'react-spring/renderprops';


export interface SpinnerButtonProps extends ButtonProps {
    loading: boolean;
    children: any;
}

export const SpinnerButton: React.FC<SpinnerButtonProps> =
    ({ children, loading, ...props }) => {
        return <Button disabled={loading} {...props}>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                {children}
                {
                    loading && <CircularProgress
                        color='inherit'
                        size='20px'
                        style={{ marginLeft: '1rem' }}
                    />
                }
            </Box>
        </Button>
    }

interface SpinnerIconButtonProps extends IconButtonProps {
    loading: boolean;
    // children: (style: React.CSSProperties) => React.ReactNode
    children: any;
}

export const SpinnerIconButton: React.FC<SpinnerIconButtonProps> =
    ({ children, loading, style, ...props }) => {
        return <IconButton disabled={loading}
            style={{ ...style, padding: '1.3rem' }} {...props}>
            <Transition items={loading}
                from={{ transform: 'scale(0, 0)' }}
                enter={{ position: 'absolute', transform: 'scale(1, 1)' } as React.CSSProperties}
                leave={{ transform: 'scale(0, 0)' }}
            >
                {
                    items => items
                        ? (props => <div className='flex-center' style={{ width: '100%', height: '100%', ...props }}>
                            <CircularProgress
                                style={{ width: '60%', height: '60%' }} /></div>)
                        : (props => children(props))
                }
            </Transition>
        </IconButton>
    }
