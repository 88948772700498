import { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/BookmarkBorder';
import SavedIcon from '@mui/icons-material/Bookmark';
import { useGetSavedSearches, useSaveSearch } from '../../api';
import { SpinnerButton } from '../../components/forms';
import { useCurrentUser } from '../../state/AppState';


interface SaveSearchButtonProps {
    query: string
}

export const SaveSearchButton: React.FC<SaveSearchButtonProps> =
    ({ query }) => {
        query = query.toLowerCase(); // Case-insensitive
        const [saved, setSaved] = useState(false);
        const { loading, request } = useSaveSearch();
        const { user } = useCurrentUser();
        const { data } = useGetSavedSearches(
            { query, savedBy: user?.email }, user ? true : false);

        const onClick = async () => {
            try {
                await request({
                    savedBy: user?.email,
                    query
                });
                setSaved(true);
            } catch (error: any) {
                console.log(error);
            }
        }

        useEffect(() => {
            if (data?.results.length === 1) {
                setSaved(true);
            }
        }, [data]);

        const icon = saved ? <SavedIcon /> : <SaveIcon />;

        return <SpinnerButton
            disabled={saved}
            loading={loading}
            onClick={onClick}
            size='small'
            startIcon={icon}
        >
            { saved ? 'saved' : 'save' }
        </SpinnerButton>
    }
