import { FC, lazy } from "react";
import { Route, RouteProps } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const PlansPage = lazy(() => import("./Plans"));

export const Plans: FC<Omit<RouteProps, 'component'>> =
    (props) => {
        const { user } = useCurrentUser(true);

        return user?.permissions?.plans
        ? <Route {...props} component={PlansPage} />
        : <Route {...props}><NotFound /></Route>
    }
