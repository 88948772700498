import clsx from 'clsx';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './style';


interface NavOptions {
    className?: string
    children: any
    style?: React.CSSProperties
    onClick?: (event: React.MouseEvent<HTMLElement>) => void,
    to?: string
}

export const Nav: React.FC<NavOptions> =
    ({ className, children, onClick, to, style }) => {
        const classes = useStyles();

        const linkProps = to
        ? { component: RouterLink, to }
        : { onClick };

        return <Link
            className={clsx(classes.nav, 'nav', className)}
            sx={{
                color: 'secondary.main',
                '&:hover': { color: 'primary.main' },
                ...style
            }}
            {...linkProps}
        >
            
            <div className='decor'></div>

            <Typography>
                {children}
            </Typography>
        </Link>;
    }
