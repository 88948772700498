import {
    Checkbox,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow
} from "@mui/material";
import { createContext, useContext, useState } from "react";
import { formatDate } from "../../helpers";


const TableContext = createContext<{
    isCheckboxVisible: boolean
    toggleCheckboxVisible: () => void
} | null>(null);

export interface TrakaTableProps {
    headers: Array<string | {
        title: string,
        align?: 'center' | 'left' | 'right',
        colSpan?: number
    }>;
    checkbox?: 'hidden' | 'visible' | 'onselect';
    children: any;
}

export const TrakaTable: React.FC<TrakaTableProps> =
    ({ headers, children, checkbox = 'onselect' }) => {
        const [state, setState] = useState({
            isCheckboxVisible: checkbox === 'visible'
        });

        const toggleCheckboxVisible = () => {
            if (checkbox === 'hidden') return;

            setState(s => ({ ...s, isCheckboxVisible: !s.isCheckboxVisible }));
        }

        return <TableContext.Provider value={{ ...state, toggleCheckboxVisible }}>
            <TableContainer component={Paper}>

                <Table>
                    <TableHead>

                        <TableRow>
                            {
                                state.isCheckboxVisible
                                && <TableCell padding='checkbox'>
                                    <Checkbox
                                        size='small'
                                        onChange={() => { }}
                                        checked={true}
                                    />
                                </TableCell>
                            }

                            {
                                headers.map((header: any, index) => (
                                    <TableCell key={index} colSpan={header.colSpan || 1}>
                                        {header.title || header}
                                    </TableCell>
                                ))
                            }
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {children}
                    </TableBody>

                </Table>

            </TableContainer>
        </TableContext.Provider>;
    }

export interface TrakaRowProps {
    data: any
    fields: Array<string | { name: string, type?: 'Date' | 'string', render?: (value: any, data: any) => any }>
    selected?: boolean
    toggleSelect?: () => void
}

export const TrakaRow: React.FC<TrakaRowProps> =
    ({ data, fields }) => {
        const tableState = useContext(TableContext);
        const types: any = {
            'Date': (value: any) => value ? formatDate(value) : '___ ___',
            'string': (value: any) => value
        }

        return <TableRow>
            {
                fields.map((field: any, index) =>
                    <TableCell key={index}>
                        {
                            field.render
                                ? field.render(
                                    field.type
                                        ? types[field.type](data[field.name])
                                        : data[field.name || field],
                                    data
                                )
                                : field.type
                                    ? types[field.type](data[field.name])
                                    : data[field.name || field]
                        }
                    </TableCell>
                )
            }
        </TableRow>;
    }
