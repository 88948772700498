import { useState } from 'react';
import * as yup from 'yup';
import { Box, Button, Dialog, DialogProps, IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import { Formik, Form, FormikHelpers } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { useLogin } from '../../api';
import { FormError } from '../../components/forms/FormError';
import { useLocationQuery } from "../../helpers";
import { Field, SpinnerButton } from '../../components/forms';
import { useAuthStyles } from './styles';
import { Person, Visibility, VisibilityOff } from '@mui/icons-material';


type Values = {
  email: string,
  password: string
}

const validationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required')
});

export const Login: React.FC = () => {
  const { redirectTo } = useLocationQuery();
  const classes = useAuthStyles();
  const history = useHistory();
  const { loading, login, error } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [showForceLogin, setShowForceLogin] = useState(false);
  const [loginData, setLoginData] = useState<Values | null>(null);

  const initialValues = { email: '', password: '' };
  const onSubmit = (values: Values, actions: FormikHelpers<Values>) =>
    login(values)
      .then(() => {
        history.push(redirectTo || '/dashboard');
      })
      .catch((error) => {
        if (error.errors) actions.setErrors(error.errors)
        if (error.code === 'ALREADY_LOGGED_IN') {
          // User is already logged in, ask the user if they wish force the other device out
          setShowForceLogin(true);
          setLoginData(values);
        }
      })
      .finally(() => actions.setSubmitting(false));

  const redirectToQueryString = redirectTo ? `?redirectTo=${redirectTo}` : '';

  if (!process.env.REACT_APP_SITE_KEY)
    return <Typography variant='h2'>CAPTCHA Key not defined</Typography>;

  return <>
    <Paper className={classes.root}>

      <Typography className={classes.header} variant='h4'>
        Nice to see you again
      </Typography>
      <Typography>
        Enter your Credentials to access your account
      </Typography>

      <FormError message={error?.message || error} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        <Form className={classes.form}>
          <Field
            name='email'
            label='Email'
            placeholder='bola@gmail.com'
            variant='outlined'
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Person />
              </InputAdornment>
            }}
          />

          <Field
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />
          <Typography align='right' className={classes.link}>
            <Link to={`/auth/forgot-password${redirectToQueryString}`}>Forgot Password?</Link>
          </Typography>

          <SpinnerButton
            className={classes.submitBtn}
            type='submit'
            color='primary'
            variant='contained'
            loading={loading}
          >
            Login
          </SpinnerButton>
        </Form>
      </Formik>

      <Typography align='center' className={classes.link}>
        Don't have an account? <Link to={`/auth/signup${redirectToQueryString}`}>Create account</Link>
      </Typography>

    </Paper >

    <ForceLoginDialog
      open={showForceLogin}
      onClose={() => setShowForceLogin(false)}
      forceLogin={() => {
        if (!loginData) return;
        
        login({ ...loginData, forceLogin: true })
          .then(() => {
            setShowForceLogin(false);
            history.push(redirectTo || '/dashboard');
          })
          .catch((error) => { console.log(error); })
          .finally(() => setLoginData(null));
      }}
      loggingIn={loading}
    />
  </>
}

const ForceLoginDialog: React.FC<Pick<DialogProps, 'open' | 'onClose'> & { forceLogin: () => void, loggingIn: boolean }> = (props) => (
  <Dialog {...props} sx={{
    '& .MuiPaper-root': {
      maxWidth: 400,
      padding: 4,
      textAlign: 'center'
    }
  }}>
    <Typography variant='h5' sx={{ mb: 2 }}>Force Login</Typography>
    <Typography>
      You are already logged in on another device, do you wish to log out from the other device?
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        marginTop: 2
      }}
    >
      <Button
        onClick={() => props.onClose?.({}, 'escapeKeyDown')}
      >
        Cancel
      </Button>
      
      <SpinnerButton
        color='primary'
        variant='contained'
        loading={props.loggingIn}
        onClick={props.forceLogin}
      >
        Force Login
      </SpinnerButton>
    </Box>
  </Dialog>
)
