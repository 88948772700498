import { useState } from 'react';
import {
    Badge,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Notifications from '@mui/icons-material/Notifications';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../state/AppState';
import { Actions } from './Actions';


const StyledMenu = withStyles((theme) => ({
    paper: {
        height: '75vh',
        maxHeight: '50rem',
        minWidth: '18rem',
        maxWidth: '30rem',
        width: '50vw'
    }
}))(Menu);

export const NotificationMenu: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const theme = useTheme();
    const history = useHistory();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { user } = useCurrentUser();
    const notifications = user?.latestNotifications || [];

    const onOpenNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isSmallScreen) {
            history.push('/notifications');
            return;
        }

        setAnchorEl(event.currentTarget);
    }
    const onMenuClose = () => setAnchorEl(null);

    return <>
        <Tooltip title="Notifications">
            <IconButton onClick={onOpenNotifications}>
                <Badge color='primary'
                    invisible={notifications.length === 0}
                    variant='dot'
                >
                    <Notifications />
                </Badge>
            </IconButton>
        </Tooltip>

        <StyledMenu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            anchorReference={'anchorEl'}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onMenuClose}>
            {
                notifications.length === 0
                    ? <Box
                        className='flex-center'
                        color='#cecece'
                        mt={'20%'}>
                        <Typography align='center' component='div'>
                            <Box component='div'
                                fontSize='3rem'
                                maxWidth='18rem'>
                                No New Notifications</Box>
                        </Typography>
                    </Box>
                    : notifications.map((notification: any, index: number) => (
                        <MenuItem key={index}>
                            <Box display='flex'
                                flexDirection='column'
                                width='100%'
                                px={1} py={2}>

                                <Box>
                                    <Typography noWrap>
                                        {notification.message}</Typography>
                                    <Typography variant={'caption'}>
                                        {new Date(notification.createdAt)
                                            .toLocaleDateString('en-gb', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                    </Typography>
                                </Box>

                                <Box mt={2}>
                                    <Actions notification={notification} />
                                </Box>

                            </Box>
                        </MenuItem>
                    ))
            }
        </StyledMenu>
    </>
}
