import { useState } from "react";
import {
    Box,
    Button,
    IconButton,
    ListItem,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import ArrowLeft from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { UserHeader } from "../../components/headers";
import { isIE } from "../../helpers";

interface GuideContainerProps {
    current: string
    guides: string[]
    path: string
    children: any
}

const AnimatedBox = animated(Box);

export const GuideContainer: React.FC<GuideContainerProps> =
    ({ children, current, guides, path }) => {
        const theme = useTheme();
        const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
        const [menuOpen, setMenuOpen] = useState(false);
        const slidingMenuWidth = '50%';
        const menuStyles = useSpring({
            left: menuOpen || !isSmallScreen ? '0%' : `-${slidingMenuWidth}`,
            width: isSmallScreen ? slidingMenuWidth : '20%'
        });
        const mainStyles = useSpring({
            right: menuOpen && isSmallScreen ? `-${slidingMenuWidth}` : '0%',
            width: isSmallScreen ? '100%' : '80%'
        });

        const maxWidthInPx = 1200;
        const padding = isIE()
            ? '10%'
            : `max(20px, calc((100vw - ${maxWidthInPx}px) / 2))`;

        return <Box
            pb={8}
            minHeight='100vh'
        >
            <UserHeader />

            <Box position='relative'>
                <AnimatedBox
                    bgcolor={theme.palette.background.default}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    pl={padding}
                    position='fixed'
                    top={0} bottom={0}
                    style={menuStyles}
                >
                    <Box>
                        <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            maxWidth={120} mb={2}
                        >
                            <Typography variant='h5'>Guides</Typography>
                            {
                                isSmallScreen && <IconButton onClick={() => setMenuOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </Box>
                        <Guides
                            current={current}
                            guides={guides}
                            path={path}
                        />
                    </Box>
                </AnimatedBox>

                <AnimatedBox
                    position='absolute'
                    top={0} bottom={0}
                    right={0} style={mainStyles}
                >
                    <Box
                        bgcolor={theme.palette.background.default}
                        minHeight={'90vh'}
                        pt={4} pb={8} pr='10%' pl={padding}
                    >
                        {
                            isSmallScreen && <Box mb={2}>
                                <Button
                                    onClick={() => setMenuOpen(true)}
                                    startIcon={<ArrowLeft />}
                                >
                                    Guides
                                </Button>
                            </Box>
                        }
                        {children as any}
                    </Box>
                </AnimatedBox>
            </Box>
        </Box>
    }


const Guides: React.FC<Omit<GuideContainerProps, 'children'>> =
    ({ current, guides, path }) => {
        const theme = useTheme();

        const style: (title: string) => React.CSSProperties =
            (title: string) => (
                current === title
                    ? {
                        color: theme.palette.primary.main,
                        fontWeight: 'bold'
                    }
                    : {}
            );

        return <>
            {
                guides.map((title, index) => (
                    <Box
                        key={index}
                    >
                        <ListItem button component={RouterLink} to={`${path}/${title}`}>
                            <Typography
                                style={style(title)}
                            >
                                {title}
                            </Typography>
                        </ListItem>
                    </Box>
                ))
            }
        </>
    }
