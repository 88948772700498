import { lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const JobsPage = lazy(() => import("./Jobs"));

export const Jobs: React.FC<Pick<RouteProps, "path">> =
    ({ path }) => {
        const { user } = useCurrentUser();

        return <Route path={path}>
            <Switch>
                {
                    user?.permissions?.marks &&
                    <Route path={`${path}/`} component={JobsPage} />
                }

                <Route path="*"><NotFound /></Route>
            </Switch>
        </Route>
    }
