import { FC, CSSProperties } from "react";
import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import { Transition } from "react-spring/renderprops";


export interface LoadingOrErrorProps {
    loading?: boolean;
    error?: Error | string;
    children: any;
}

export const LoadingOrErrorIndicator: FC<LoadingOrErrorProps> = ({ children }) => <>{children}</>

const Loader = (props: CSSProperties) => (
    <Box style={props}>
        <Box my={2}>
            <Skeleton height='3rem' />
        </Box>

        <Box my={2}>
            <Skeleton height='3rem' />
        </Box>

        <Box my={2}>
            <Skeleton height='3rem' />
        </Box>

        <Box my={2}>
            <Skeleton height='3rem' />
        </Box>

        <Box my={2}>
            <Skeleton height='3rem' />
        </Box>
    </Box>
);

export const TableLoader: FC<{ loading?: boolean, children: any }> = ({ children, loading }) => (
    <Box position='relative'>
        <Transition
            items={loading}
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{
                opacity: 0,
                position: 'absolute',
                left: 0, right: 0,
                top: 0, bottom: 0
            } as CSSProperties
            }
        >
            {
                items => items
                    ? (props => Loader(props))
                    : (props => <Box style={props}>{children}</Box>)
            }
        </Transition>
    </Box>
)

