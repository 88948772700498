interface AmountProps {
    amount?: number;
    currency: string;
    sign?: boolean;
}

export const Amount: React.FC<AmountProps> = ({ amount = 0, currency, sign }) => {
    let formatter = new Intl
        .NumberFormat(
            undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        );

    if (currency === 'NGN' && sign) {
        return <>{currency} {formatter.format(amount)}</>
    }
    if (currency === 'NGN' || currency == '') {
        return <>{formatter.format(amount)}</>
    }

    formatter = new Intl
        .NumberFormat(
            'en-GB',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                ...(sign ? {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'narrowSymbol'
                } : {})
            }
        );

    return <>{formatter.format(amount)}</>;
}
