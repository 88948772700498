import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


export const useLocationQuery = (): { [P in keyof any]: any } => {
    const location = useLocation();
    const params: any = useMemo(() => {
        const result: any = {};
        
        const queryParams = new URLSearchParams(location.search);
        queryParams.forEach((value, key) => { result[key] = value; });

        return result;
    }, [location.search]);

    return params;
}

export const useUpdateLocationQuery = () => {
    const history = useHistory();
    const location = useLocation();

    const update = (params: any, route?: string) => {
        const queryParams = new URLSearchParams(location.search);
        Object.keys(params).forEach((key: string) => {
            if (params[key] === undefined) return;
            queryParams.set(key, params[key]);
        });

        history.push(`${route || location.pathname}` +
            `?${queryParams.toString()}`);
    }

    return update;
}

export const useListModifiers = (key: string, data?: any[]) => {
    const [added, setAdded] = useState<any[]>([]);
    const [updates, setUpdates] = useState<any>({});
    const [removed, setRemoved] = useState<any>({});

    const clearModifications = () => {
        setAdded([]);
        setUpdates({});
        setRemoved({});
    }

    const modified = data
        ? [...data, ...added]
            .filter((v) => !removed[v[key]])
            .map((v) => updates[v[key]] ? { ...v, ...updates[v[key]] } : v)
        : undefined;

    const addToList = (item: any) => setAdded(list => ([...list, item]));
    const updateItemInList = (item: any) =>
        setUpdates((prev: any) => {
            const itemKey = item[key];
            const prevUpdate = prev[itemKey] || {};

            return { ...prev, [itemKey]: { ...prevUpdate, ...item } };
        });
    const removeFromList = (itemKey: string) =>
        setRemoved((prev: any) => ({ ...prev, [itemKey]: true }));


    return {
        modified,
        clearModifications,
        addToList,
        updateItemInList,
        removeFromList
    };
}

export const useInfiniteLoader = (loadMore: () => Promise<any[]>, initialData: any[] = []) => {
    const [data, setData] = useState(initialData);
    const { enqueueSnackbar } = useSnackbar();

    const appendToData = (moreData: any[]) => {
        setData((currentData) => ([...currentData, ...moreData]));
    }

    const onScrollEnd = async () => {
        try {
            const moreData = await loadMore();
            appendToData(moreData);
        } catch (error: any) {
            enqueueSnackbar(
                'Failed to fetch more results',
                { variant: 'error' }
            );
        }
    }

    return { data, onScrollEnd };
}

export type FieldSelector<T> = {
    [P in keyof T]?: boolean
}

export function objectToFormData(object: any) {
    const formData = new FormData();

    Object.keys(object)
        .forEach((key) => {
            const value = object[key];

            if (Array.isArray(value)) {
                value.forEach((v) => formData.append(key, v));
            } else {
                formData.set(key, value);
            }
        });

    return formData;
}

export function unformatAmount(amount: string) {
    amount = amount.replaceAll ? amount.replaceAll(',', '') : amount;
    return Number.parseFloat(amount);
}

export function formatDate(date: Date | string) {
    return new Date(date)
        .toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        })
}

export function isIE() {
    const ua = window.navigator.userAgent;
    const trident = ua.indexOf("Trident/");
    return Boolean(trident > -1);
}

export function fieldUpdate(field: string, stateUpdate: Function) {
    return (val: any) => {
        stateUpdate((state: any) => ({ ...state, [field]: val }));
    }
}

export function useDebouncer(value: any, durationInMs: number, callback: Function) {
    useEffect(() => {
        const timeout = setTimeout(callback, durationInMs);
        return () => clearTimeout(timeout);
    }, [value, durationInMs, callback]);
}
