import clsx from 'clsx';
import {
    Box,
    Container,
    Typography,
    Button,
    Collapse
} from "@mui/material";
import { Link, useHistory } from 'react-router-dom';
import { animated, Transition } from 'react-spring/renderprops';
import { useState } from 'react';
import {
    ResponsiveSearchBar,
    SearchResults,
    SaveSearchButton,
} from "../../components/search";
import { Amount, Pagination } from '../../components/display';
import { useUpdateLocationQuery } from '../../helpers';
import { useStyles } from './styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ExportSearchResultsButton } from '../../components/search/ExportSearchResultsButton';


interface SearchWithQueryProps {
    query: string
    data?: any
    error?: any
    loading?: boolean
}

export const SearchWithQuery: React.FC<SearchWithQueryProps> =
    ({ data, loading, query, error }) => {
        const classes = useStyles();
        const update = useUpdateLocationQuery();

        const requiresPayment = data?.hasPaid === false;
        const isEmpty = !requiresPayment && !loading && !error
            && data?.count === 0;

        const skip = data?.page?.skip || 0;
        const limit = data?.page?.limit || 0;
        const count = data?.count || 0;
        const startIndex = Math.min(skip + 1, count);
        const endIndex = Math.min(skip + limit, count);

        const [showSearchParams, setShowSearchParams] = useState(true);

        return <Box className={classes.content}>
            <Box
                bgcolor='white'
                borderBottom='1px solid #cecece'
                pt={4} pb={1}
            >
                <Container>
                    <Collapse in={showSearchParams} collapsedSize={50}>
                        <ResponsiveSearchBar
                            className={''}
                            loading={loading}
                            size='big'
                            variant='full'
                        />
                    </Collapse>

                    <Box display='flex' alignItems='center' mt={2}>

                        {/* TODO Remove <SearchTypeSelector /> */}

                        <Box mr={1}>
                            <SaveSearchButton query={query} />
                        </Box>

                        <Box mr={2}>
                            <ExportSearchResultsButton results={data?.results ?? []} />
                        </Box>

                        <Box ml='auto'>
                            <Button
                                onClick={() => setShowSearchParams(!showSearchParams)}
                                startIcon={
                                    showSearchParams
                                        ? <ArrowDropUp fontSize='large' />
                                        : <ArrowDropDown fontSize='large' />
                                }
                                size='small'
                            >
                                {
                                    showSearchParams
                                        ? 'Hide Parameters' : 'Show Parameters'
                                }
                            </Button>
                        </Box>
                    </Box>
                </Container>

            </Box>

            <Box height='2rem'>
                <Container>
                    {
                        data?.count !== undefined && <Typography variant='overline'>
                            <strong>
                                Showing {startIndex} to {endIndex} of{' '}
                                {count} result{count > 1 && 's'}
                            </strong>
                        </Typography>
                    }
                </Container>
            </Box>
            <Box
                className='pad'
                position='relative'
                pb={8}
            >
                {
                    (!isEmpty || loading) && !error &&
                    <Box
                        className={clsx({ 'blur': requiresPayment })}
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <SearchResults
                            className={clsx(classes.results)}
                            loading={loading}
                            limits={data?.limits}
                            data={data?.results} />

                        <Box px={2} mt={4}>
                            <Pagination
                                disabled={loading}
                                next={endIndex < count}
                                onPageChange={update}
                            />
                        </Box>
                    </Box>
                }

                <SlideUpOn
                    on={requiresPayment}
                    className={clsx(classes.payment)}
                    style={{
                        position: 'absolute',
                        left: '0', right: '0',
                        top: '0', bottom: '0%'
                    }}
                >
                    <RequiresPayment plans={data?.plans} query={query} />
                </SlideUpOn>


                <SlideUpOn on={isEmpty}>
                    <div style={{ marginTop: '10vh' }}>
                        <Typography align='center' style={{ fontSize: '2rem', marginBottom: '1rem' }}>
                            <strong>Oops! We found no records matching your query</strong>
                        </Typography>
                        <Typography align='center'>Try searching something else</Typography>
                    </div>
                </SlideUpOn>

                <SlideUpOn on={(error && true)}>
                    <div style={{ marginTop: '10vh' }}>
                        <Typography align='center' style={{ fontSize: '2rem', marginBottom: '1rem' }}>
                            <strong>Oops! An error has occured</strong>
                        </Typography>

                        <Box display='flex' justifyContent='center'>
                            <Button
                                onClick={() => {
                                    window.location.reload()
                                }}
                                variant='contained'
                                color='primary'
                            >Try reloading this page</Button>
                        </Box>
                    </div>
                </SlideUpOn>
            </Box>
        </Box>
    }


const SlideUpOn: React.FC<{
    className?: string,
    on: boolean,
    style?: React.CSSProperties,
    children: any
}> =
    ({ children, on, style }) => (
        <Transition native items={on}
            from={{ transform: 'translateY(100%)' }}
            enter={{ transform: 'translateY(0)' }}
            leave={{ transform: 'translateY(100%)' }}>
            {item => item && (
                animatedProps =>
                    <animated.div
                        style={{ ...animatedProps, ...style }}
                    >
                        {children}
                    </animated.div>
            )
            }
        </Transition>
    )


const RequiresPayment: React.FC<{ plans?: any[] } & Pick<SearchWithQueryProps, 'query'>> =
    ({ query, plans }) => {
        const history = useHistory();
        const oneTimePlans = plans?.filter((plan: any) => plan.period === 'once');

        return <>
            <Typography align='center' style={{ fontSize: '2rem', marginBottom: '1rem' }}>
                <strong>You need to pay to view your search results!</strong>
            </Typography>

            {
                oneTimePlans && oneTimePlans.length > 0
                    ? <>
                        <Typography align='center'>
                            Purchase any of the following one-time plans to view your results
                        </Typography>

                        <Box className='flex-center' my={2} style={{ height: 'auto' }}>
                            {
                                oneTimePlans
                                    .map((plan: any, index: number) => (
                                        <Box key={index} my={1}>
                                            <Button
                                                onClick={() => history.push(
                                                    `/subscriptions/${plan._id}/pay?query=${query}`)}
                                                color='primary' variant='contained'>
                                                Purchase {plan.name} for <Amount
                                                    currency={plan.currency} amount={plan.amount} sign />
                                            </Button>
                                        </Box>
                                    ))
                            }
                        </Box>

                        <Typography align='center'>
                            Or <Link to='/subscriptions' target='_blank'>
                                check out our other subscription plans</Link>
                        </Typography>
                    </>
                    : <>
                        <Typography align='center'>
                            <Link to='/subscriptions' target='_blank'>
                                check out our subscription plans here
                            </Link>
                        </Typography>
                    </>
            }


        </>
    }
