import { Route, RouteProps } from 'react-router-dom';
import { Search as SearchPage } from './Search';
import { SearchHome } from './Home';

export const Search: React.FC<Pick<RouteProps, 'path'>> = ({ path }) => {
    return <>
        {/* <Route path={`${path}/results/:id`} /> */}
        <Route path={`${path}results`} component={SearchPage} />
        <Route path={`${path}`} component={SearchHome} exact />
    </>;
}
