import { useEffect } from 'react';
import clsx from 'clsx';
import {
    Box,
    useTheme
} from "@mui/material";
import { useSearch } from "../../api";
import { useLocationQuery } from "../../helpers";
import { UserHeader } from '../../components/headers';
import { useStyles } from './styles';
import { SearchWithQuery } from './SearchWithQuery';
import { useCurrentUser } from '../../state/AppState';

export const Search: React.FC = () => {
    useCurrentUser(true);
    const classes = useStyles();
    const params = useLocationQuery();
    const { loading, data, request, error, clear } = useSearch();
    const theme = useTheme();

    useEffect(() => {
        let limit = params._limit;
        let skip = params._skip;
        if (limit === undefined) limit = 100;
        if (skip === undefined) skip = 0;

        (async () => {
            try {
                await request(null, {
                    ...params,
                    query: params.search,
                    _limit: limit,
                    _skip: skip
                });
            } catch (error: any) {
                console.error(error);
            }
        })();
    }, [params, request]);

    const requiresPayment = data?.plans?.length > 0;

    return <Box
        bgcolor={theme.palette.background.default}
        className={clsx(classes.root, { 'h-screen': requiresPayment })}>
        <UserHeader />

        <SearchWithQuery
            query={params.search ?? ""}
            data={data}
            loading={loading}
            error={error}
        />
    </Box>;
}
