import { Box } from '@mui/material';
import { useAcceptInvite, useRejectInvite, useUpdateNotifications } from '../../api';
import { SpinnerButton } from '../../components/forms';
import { useCurrentUser } from '../../state/AppState';


enum NotificationType {
    invite = 'invite',
    subscription = 'subscription',
    security = 'security'
}


export const Actions: React.FC<{ notification: any }> =
    ({ notification }) => {
        const { user, setUser } = useCurrentUser();
        const { loading: marking, request } = useUpdateNotifications();
        const { request: acceptInvite, loading: accepting } = useAcceptInvite(notification.data?._id);
        const { request: rejectInvite, loading: rejecting } = useRejectInvite(notification.data?._id);

        const mark = async () => {
            try {
                await request({ read: true }, { _id: notification._id });
                if (!setUser) return;

                setUser({
                    ...user,
                    latestNotifications: user?.latestNotifications
                        ?.filter((value: any) => value._id !== notification._id)
                });
            } catch (e: any) { }
        }


        return <>
            {(
                !notification.read &&
                    notification.type === NotificationType.invite
                    ? <Box display='flex' alignItems='center'>
                        <SpinnerButton
                            loading={rejecting}
                            onClick={() => {
                                rejectInvite().catch(console.error);
                                mark().catch(console.error);
                            }}
                            size='small'
                            style={{ marginRight: '1rem' }}
                        >
                            Reject
                        </SpinnerButton>

                        <SpinnerButton
                            loading={accepting}
                            onClick={() => {
                                acceptInvite().catch(console.error);
                                mark().catch(console.error);
                            }}
                            color='primary'
                            variant='contained'
                            size='small'
                        >
                            Accept
                        </SpinnerButton>
                    </Box>
                    : <SpinnerButton
                        loading={marking}
                        onClick={mark}
                        color='primary'
                        size='small'
                        style={{ textTransform: 'none' }}>
                        Mark as read
                    </SpinnerButton>
            )}
        </>
    }

