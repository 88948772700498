import { FC, lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";


const UsersPage = lazy(() => import("./Users"));

export const Users: FC<Pick<RouteProps, 'path'>> = ({ path }) => {
    const { user } = useCurrentUser(true);

    return <Route path={path}>
        <Switch>
            {
                user?.permissions?.users &&
                <Route path={path} component={UsersPage} />
            }
            <Route path='*'><NotFound /></Route>
        </Switch>
    </Route>
}