import { FC, useState } from "react";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFulfillInvoice, useCancelInvoice, useRevertToPendingInvoice } from "../../api";

const STATUS_TO_OPTIONS = {
    PENDING: ['PENDING', 'FULFILLED', 'CANCELED'],
    FULFILLED: ['PENDING', 'FULFILLED'],
    CANCELED: ['PENDING', 'CANCELED']
};

export const Actions: FC<{
    id: string,
    status: 'PENDING' | 'FULFILLED' | 'CANCELED',
    onChange: () => void
}> = ({ id, status, onChange }) => {
    const [loading, setLoading] = useState(false);
    const { request: fulfill } = useFulfillInvoice(id);
    const { request: cancel } = useCancelInvoice(id);
    const { request: revert } = useRevertToPendingInvoice(id);


    const handleChange = async (event: SelectChangeEvent) => {
        if (loading) return;
        try {
            setLoading(true);
            switch(event.target.value as string) {
                case 'PENDING': {
                    await revert();
                    break;
                }
                case 'FULFILLED': {
                    await fulfill();
                    break;
                }
                case 'CANCELED': {
                    await cancel();
                    break;
                }
                default: throw new Error(`Invalid status: ${event.target.value}`)
            }
            onChange();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return <>
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 200 }}>
            {
                loading && <CircularProgress size={20} />
            }

            <FormControl sx={{ flexGrow: 1, ml: loading ? 2 : 0 }}>
                <InputLabel id="demo-simple-select-label">Change Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Change Status"
                    onChange={handleChange}
                    disabled={loading}
                    size='small'
                >
                    {
                        STATUS_TO_OPTIONS[status]
                            .map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    </>
}
