import clsx from 'clsx';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Box,
  Hidden,
  Button,
  IconButton,
  InputBase,
  Paper,
  Theme,
  CircularProgress,
  Collapse,
  PaperProps,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useLocationQuery, useUpdateLocationQuery } from '../../helpers';
import { FadeTransition } from '../animations';
import React, { useState } from 'react';
import { AdvancedOptions } from './AdvancedOptions';


const validationSchema = yup.object({
  class: yup.string()
    .matches(/[0-9]{1}/, "Please enter a valid class e.g. 5"),
  journalYear: yup.string()
    .matches(/[0-9]{4}/, "Please enter a valid year e.g. 2020"),
});

export interface SearchBarProps {
  className?: string
  loading?: boolean
  size?: 'small' | 'big'
  routeOnSearch?: boolean
  variant?: 'full' | 'mini'
}

interface FormValues {
  search: string;
  applicationNumber?: string;
  proprietor?: string;
  class: string;
  journalYear?: string;
  mode: 'contains' | 'similarity';
}

export const SearchBar: React.FC<SearchBarProps> =
  ({ className, loading, routeOnSearch = true, size = 'big', variant = 'mini' }) => {
    const classes = useStyles();
    const values = useLocationQuery() as Partial<FormValues>;
    const updatePath = useUpdateLocationQuery();
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const initialValues: FormValues = {
      search: values.search ?? '',
      class: values.class ?? '',
      mode: values.mode ?? 'contains',
      journalYear: values.journalYear ?? '',
      applicationNumber: values.applicationNumber ?? '',
      proprietor: values.proprietor ?? ''
    };
    const onSubmit = (values: FormValues) => {
      const exemptedFields = ['mode'];
      const isEnoughParamsSet = Object.keys(values)
        .filter((key) => !exemptedFields.includes(key))
        .map((key) => (values as any)[key])
        .reduce((acc, value) => acc || Boolean(value), false);

      if (!isEnoughParamsSet) {
        setShowDialog(true);
        return;
      }

      updatePath({ ...values, _skip: 0 }, '/search/results');
    };

    const uiVariantMapper: Record<'full' | 'mini', {
      root: Partial<PaperProps>;
      collapsible: boolean;
    }> = {
      'full': {
        root: { elevation: 0 },
        collapsible: false,
      },
      'mini': {
        root: { variant: 'outlined' },
        collapsible: true
      }
    };
    const uiProps = uiVariantMapper[variant];

    const variableSchemaProps = yup.object({
      search: uiProps.collapsible && !showAdvancedOptions
        ? yup.string().required() : yup.string()
    });

    return <>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>You have not added any search parameters</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please enter a trademark or device query or use the advanced search options
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setShowDialog(false)}
          >Ok</Button>
        </DialogActions>
      </Dialog>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema.concat(variableSchemaProps)}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form className={clsx(className, classes[size])}>

            <Paper className={clsx(classes.searchRoot)} {...uiProps.root}>
              <Box className={clsx(classes.searchBar)}>
                {
                  uiProps.collapsible && <Box mr={2}>
                    <Button
                      color='secondary'
                      onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                      endIcon={<ArrowDropDown fontSize='large' />}
                      size='small'
                    >
                      Advanced
                    </Button>
                  </Box>
                }

                <InputBase
                  className={classes.searchInput}
                  name='search'
                  value={values.search}
                  onChange={handleChange}
                  placeholder='Search Word Marks, Devices...'
                  inputProps={{ 'aria-label': 'search jeetraka' }}
                  style={{
                    fontSize: 'inherit',
                    borderBottom: !uiProps.collapsible || showAdvancedOptions ? '1px solid #CCCCCC' : ''
                  }} />


                <FadeTransition className={classes.searchIcon} show={loading}
                  items={[

                    <CircularProgress size={size === 'small' ? 15 : 20} />,
                    <IconButton
                      type="submit"
                      aria-label="search"
                      sx={{
                        color: 'white',
                        backgroundColor: 'secondary.main',
                        '& :hover': {
                          color: 'secondary.main'
                        }
                      }}
                    >
                      <SearchIcon className='searchIcon' />
                    </IconButton>
                  ]} />
              </Box>

              {
                ((content: any) => (
                  uiProps.collapsible
                    ? <Collapse in={showAdvancedOptions}>{content}</Collapse>
                    : content
                ))(
                  <Box mt={4} mb={2}>
                    <AdvancedOptions
                      data={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                )
              }
            </Paper>

          </Form>
        )}
      </Formik>
    </>
  }

export const ResponsiveSearchBar: React.FC<SearchBarProps> = (props) => <>
  <Hidden smUp implementation='css'>
    <SearchBar {...props} size='small' />
  </Hidden>

  <Hidden smDown implementation='css'>
    <SearchBar {...props} />
  </Hidden>
</>

const useStyles = makeStyles((theme: Theme) => createStyles({
  searchIcon: {
    position: 'relative',
    height: 40,
    width: 40
  },
  searchInput: {
    flex: 1
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchRoot: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    // borderRadius: '0.5rem',
    borderRadius: '50px !important',
  },
  small: {
    fontSize: '0.8rem',
    // height: '1.2rem',
    minWidth: '16rem',
    maxWidth: '30rem',

    '& .searchIcon': {
      width: '1rem',
      height: '1rem'
    }
  },
  big: {
    fontSize: '1rem',
    // height: '2rem',
    minWidth: '30rem',
    // maxWidth: '50rem'
  }
}));

