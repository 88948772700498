import { Box, MenuItem, TextField } from "@mui/material";

export interface IAdvancedOptionsProps {
    data: {
        applicationNumber?: string,
        proprietor?: string,
        class: string,
        journalYear?: string,
        mode: 'contains' | 'similarity'
    };
    errors: Partial<Record<'class' | 'journalYear', string>>;
    setFieldValue: (name: string, value: any) => void;
}

export const AdvancedOptions: React.FC<IAdvancedOptionsProps> = ({ data, errors, setFieldValue }) => {
    return <>
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            mb={4}
        >
            <Box width={'48%'} minWidth={'20rem'}>
                <TextField
                    variant="standard"
                    id="enter-application-no"
                    label="Application Number"
                    value={data.applicationNumber ?? ''}
                    onChange={(e) => setFieldValue('applicationNumber', e.target.value)}
                    helperText="Search by application number"
                    style={{ width: '100%' }}
                    size="small" />
            </Box>

            <Box width={'48%'} minWidth={'20rem'}>
                <TextField
                    variant="standard"
                    id="enter-proprietor-name"
                    label="Proprietor's name"
                    value={data.proprietor ?? ''}
                    onChange={(e) => setFieldValue('proprietor', e.target.value)}
                    helperText="Search by Proprietor Name"
                    style={{ width: '100%' }}
                    size="small" />
            </Box>
        </Box>

        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            maxWidth={'32rem'}
        >
            <TextField
                variant="standard"
                id="class"
                label="Class"
                helperText={errors.class ?? "Enter a class"}
                value={data.class}
                onChange={(e) => setFieldValue('class', e.target.value)}
                style={{ width: '100%', maxWidth: '15rem', marginBottom: '1rem' }}
                size="small" />

            <TextField
                variant="standard"
                id="enter-journal-year"
                label="Journal Year"
                value={data.journalYear}
                onChange={(e) => setFieldValue('journalYear', e.target.value)}
                helperText={errors.journalYear ?? "Search by journal year"}
                style={{ width: '100%', maxWidth: '15rem', marginBottom: '1rem' }}
                error={Boolean(errors.journalYear)}
                size="small" />

            {/* <TextField
                variant="standard"
                id="select-search-mode"
                select
                label="Search Mode"
                value={data.mode}
                onChange={(e) => setFieldValue('mode', e.target.value)}
                helperText="Please select your search mode"
                style={{ width: '100%', maxWidth: '15rem', marginBottom: '1rem' }}
                size="small">
                <MenuItem value='contains'>Contains</MenuItem>
                <MenuItem value='similarity'>Similarity</MenuItem>
            </TextField> */}
        </Box>
    </>;
} 
