import Alert from '@mui/lab/Alert';
import { Transition } from 'react-spring/renderprops';

interface FormErrorProps {
    /**
     * An error fades in when message is set
     */
    message?: string
}

export const FormError: React.FC<FormErrorProps> = ({ message }) => {
    const show = message ? true : false;
    return <Transition
        items={show}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}>
        {show => show && (
            props => <div
                style={{
                    ...props,
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                }}>
                <Alert severity='error'>{message}</Alert>
            </div>
        )}
    </Transition>
}
