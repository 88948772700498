import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Paper, Typography } from '@mui/material';
import { useRequestChangePasswordLink } from '../../api';
import { FormError } from '../../components/forms/FormError';
import { Field, SpinnerButton } from '../../components/forms';
import { useAuthStyles } from './styles';
import { FadeTransition } from '../../components/animations';
import { useLocationQuery } from '../../helpers';


const validationSchema = yup.object({
    email: yup.string().email().required('Email is required')
});

export const ForgotPassword: React.FC = () => {
    const classes = useAuthStyles();
    const { redirectTo } = useLocationQuery();
    const { loading, request, error } = useRequestChangePasswordLink();
    const [sent, setSent] = useState(null);
    const initialValues = { email: '' };

    const onSubmit = async (values: any) => {
        try {
            await request(values);
            setSent(values);
        } catch (error: any) {
            console.log(error);
        }
    };

    const redirectToQueryString = redirectTo ? `?redirectTo=${redirectTo}` : '';

    const form = <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>

        <Form className={classes.form}>

            <Typography align='center'>
                We will send a password reset link to your email
            </Typography>

            <Field
                name='email'
                label='Email'
                placeholder='bola@gmail.com'
                variant='outlined'
            />

            <SpinnerButton
                className={classes.submitBtn}
                type='submit'
                color='primary'
                variant='contained'
                loading={loading}
            >
                Send link
            </SpinnerButton>
        </Form>

    </Formik>;
    const alreadySent = <>
        <Typography align='center' style={{ fontSize: '1.2rem' }}>
            A password reset link has been sent to your email
        </Typography>
        <Typography align='center' style={{ fontSize: '1.2rem' }}>
            Follow it to reset your password
        </Typography>

        <Typography align='center' style={{ marginTop: '3rem' }}>
            Didn't get the link?
        </Typography>

        <SpinnerButton
            className={classes.submitBtn}
            loading={loading}
            color='primary'
            variant='contained'
            onClick={() => onSubmit(sent)}
        >
            Resend link
        </SpinnerButton>
    </>;


    return <Paper className={classes.root}>
        <Typography className={classes.header} variant='h4'>
            Forgot Password
        </Typography>

        <FormError message={error?.message || error} />

        <FadeTransition show={Boolean(sent)}
            style={{ position: 'relative', height: '15rem' }}
            align='stretch'
            items={[
                alreadySent,
                form
            ]} />

        <Typography align='center' className={classes.link}>
            Remembered your password? <Link component={RouterLink} to={`/auth/login${redirectToQueryString}`}>
                Login</Link>
        </Typography>
        <Typography align='center' className={classes.link}>
            Don't have an account? <Link component={RouterLink} to={`/auth/signup${redirectToQueryString}`}>
                Signup</Link>
        </Typography>

        <Box mt={4}>
            <Typography variant='body2' align='center'>
                If you have any issues, please <Link component={RouterLink} to='/contact-us'>Contact Us</Link>
            </Typography>
        </Box>

    </Paper>
}
