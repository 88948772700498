import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useAuthStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            minHeight: '30rem',
            width: '90vw',
            maxWidth: '30rem',
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(8),
                paddingBottom: theme.spacing(8),
                paddingLeft: theme.spacing(6), paddingRight: theme.spacing(6),
            }
        },
        header: {
            textAlign: 'left',
            flexGrow: 0.5
        },
        form: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            flexGrow: 0.8,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'center',
            width: '100%'
        },
        submitBtn: {
            marginTop: theme.spacing(4),
            minWidth: '5rem',
            width: '100%', height: '52px',
            marginLeft: 'auto', marginRight: 'auto'
        },
        link: {
            fontSize: '0.9rem',
            marginTop: theme.spacing(),
            marginBottom: theme.spacing()
        }
    })
);
