import { Box, BoxProps, TableCell, TableCellProps } from "@mui/material";


export const HideableTableCell: React.FC<{ hide?: boolean } & TableCellProps> =
    ({ hide, ...props }) => (
        hide
            ? <></>
            : <TableCell {...props} />
    )


export const HideableBox: React.FC<{ hide?: boolean } & BoxProps> =
    ({ hide, ...props }) => (
        hide
            ? <></>
            : <Box {...props} />
    )

