import { useMemo, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowLeft from "@mui/icons-material/ArrowBackIos";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";

interface PaginationProps {
    align?: 'center' | 'left' | 'right'
    disabled?: boolean

    /**
     * Enable next button?
     */
    next?: boolean
    skip?: number
    count?: number
    limit?: number
    onPageChange: (props: any) => void
}

const LIMIT = 100;

export const Pagination: React.FC<PaginationProps> =
    ({ align = 'right', disabled, onPageChange, skip, count, limit = LIMIT, next = true }) => {
        const [page, setPage] = useState(skip ? skip / limit : 0);
        const pages = useMemo(() => {
            if (count === undefined) return [];

            const nPages = Math.ceil(count / limit);
            const hidden = []
            if (nPages > 10) hidden.push({ start: 6, end: nPages - 5 });

            const pages = [];
            let i = 0;
            while (i < nPages) {
                let hideStart = i;
                let hideEnd = i + 1;
                hidden.forEach((value) => {
                    if (i !== value.start) return;
                    hideStart = value.start;
                    hideEnd = value.end;
                });
                let length = hideEnd - hideStart;
                pages.push({ text: length > 1 ? '...' : i + 1, page: hideStart });
                i += length;
            }
            return pages;
        }, [page, count, limit]);

        const ALIGNMENT = {
            'center': 'center',
            'left': 'flex-start',
            'right': 'flex-end'
        }

        const incrementPage = (amount: number) =>
            setPage(p => {
                const page = Math.max(0, p + amount);
                onPageChange({ _limit: limit, _skip: (page * limit) });
                return page;
            });

        return <Box
            display='flex'
            alignItems='center'
            justifyContent={ALIGNMENT[align]}
        >

            <Box mr={2}>
                <Button
                    disabled={disabled || page === 0}
                    onClick={() => incrementPage(-1)}
                    startIcon={<ArrowLeft style={{ fontSize: 15 }} />}
                >
                    Previous
                </Button>
            </Box>

            {
                pages.map((value) => <IconButton key={value.page} size='small' onClick={() => {
                    setPage(value.page);
                    onPageChange({ _limit: limit, _skip: (value.page * limit) });
                }}
                    style={{
                        color: page === value.page ? 'black' : undefined,
                        marginRight: '8px'
                    }}
                >{value.text}</IconButton>)
            }

            <Button
                disabled={disabled || !next}
                onClick={() => incrementPage(1)}
                endIcon={<ArrowRight style={{ fontSize: 15 }} />}
            >
                Next
            </Button>

        </Box>
    }
