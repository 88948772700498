import { FC, Fragment, useEffect, useState, useRef, ReactNode } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  InputAdornment,
  MenuItem,
  IconButton,
  TextField
} from "@mui/material";
import FilterIcon from "@mui/icons-material/Tune";
import SearchIcon from '@mui/icons-material/Search';
import { Formik, Form } from 'formik';
import { useSnackbar } from "notistack";
import { Field, SelectField } from '../forms';


export interface Filter {
  label: string
  values?: Array<{ value: any, label: string }>
  type?: keyof typeof FilterTypes
}

interface FiltersProps {
  filters: Record<string, Filter> | Promise<Record<string, Filter>>
  initialValue: any
  value: any
  onChange: (filter: any) => void
  icon?: React.ReactNode
}

const FilterTypes = {
  'String': (name: string, filter: Filter) =>
    <Field name={name} label={filter.label} />,
  'Date': (name: string, filter: Filter) =>
    <Field
      name={name}
      label={filter.label}
      type='Date'
      InputLabelProps={{
        shrink: true
      }}
    />,
  'Select': (name: string, filter: Filter) =>
    <SelectField
      name={name}
      label={filter.label}
      style={{ width: '100%', margin: '12px 0' }}
      displayEmpty={true}
    >
      {
        filter.values?.map(
          (value, index) => <MenuItem value={value.value} key={index}>
            {value.label}
          </MenuItem>
        )
      }
    </SelectField>,
  'Number': (name: string, filter: Filter) =>
    <Field
      name={name}
      label={filter.label}
      type='Number'
    />
}

export const Filters: FC<FiltersProps> =
  ({ filters: promisedFilters, initialValue, value, onChange, icon }) => {
    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState<Record<string, Filter>>({});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      if (promisedFilters instanceof Promise) {
        promisedFilters
          .then(setFilters)
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(
              `Cannot load filters: ${err.message || 'An unexpected error occured'}`,
              { variant: 'error' }
            );
          });
      } else {
        setFilters(promisedFilters);
      }
    }, [promisedFilters]);

    const on = Object.keys(filters)
      .reduce((prev: boolean, cur: any) => prev || value[cur], false);

    const doChange = (values: any) => {
      onChange(values);
      setOpen(false);
    }

    const color = on ? 'primary' : undefined;

    const handleReset = () => {
      doChange(initialValue);
    }

    return <>
      <Hidden smUp>
        <IconButton
          color={color}
          onClick={() => setOpen(true)}
        >
          {icon ?? <FilterIcon />}
        </IconButton>
      </Hidden>

      <Hidden xsDown>
        <Button
          color={color}
          onClick={() => setOpen(true)}
          startIcon={icon ?? <FilterIcon />}
          size='small'
        >
          Filter{on && ' ON'}
        </Button>
      </Hidden>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>Filter</DialogTitle>

        <DialogContent>
          <Formik
            initialValues={value}
            onSubmit={doChange}
          >
            <Form>

              {
                Object
                  .keys(filters)
                  .map((key, index: number) => {
                    const filter = filters[key];
                    let type = filter.type || 'String';
                    type = filter.values ? 'Select' : type;

                    return <Fragment key={index}>
                      {FilterTypes[type](key, filter)}
                    </Fragment>
                  })
              }

              <DialogActions>

                <Button onClick={handleReset} type='button'>
                  Reset
                </Button>

                <Button color='primary' type='submit'>
                  Filter
                </Button>

              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  }


export const DataSearchBox: FC<{
  loading: boolean,
  placeholder?: string,
  debounceDuration?: number,
  endAdornment?: ReactNode,
  onSearch(term: string): void
}> = ({
  loading,
  placeholder = 'Search',
  debounceDuration = 1000,
  endAdornment,
  onSearch
}) => {
  const [text, setText] = useState("");
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  return <TextField
    variant='outlined'
    placeholder={placeholder}
    size="small"
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          {
            loading ? <CircularProgress size={24} /> : <SearchIcon />
          }
        </InputAdornment>
      ),
      endAdornment
    }}
    value={text}
    onChange={(e) => {
      setText(e.target.value);
      if (timeout.current !== null) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => onSearch(e.target.value), debounceDuration);
    }}
    sx={{
      backgroundColor: 'white'
    }}
  />
}
