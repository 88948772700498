import { lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { useCurrentUser } from "../../state/AppState"
import { Invoice } from "./Invoice";

const AllInvoices = lazy(() => (import('./AllInvoices')));

export const Invoices: React.FC<Pick<RouteProps, 'path'>> =
    ({ path }) => {
        const { user } = useCurrentUser();

        return <Route path={path}>
            <Switch>
                <Route path={`${path}/all`} component={AllInvoices} exact />
                <Route path={`${path}/:invoiceId`} component={Invoice} />
            </Switch>
        </Route>
    }
