import { Box, Container, Typography } from "@mui/material";
import { UserHeader } from "../../components/headers";
import { Footer } from "../../components/Footer";

export const DemoVideo = () => {
  const vidLink = "https://jee-website-files.s3.eu-west-2.amazonaws.com/Jeetraka+Final+V2.0.mp4";

  return <>
    <Box sx={{

    }}>
      <UserHeader />

      <Container sx={{ py: 8 }}>

        <Typography variant='h2' sx={{ mb: 2 }}>Product Tour</Typography>

        <video src={vidLink} autoPlay controls style={{
          border: '1px black solid',
          borderRadius: '10px',
          width: '100%'
        }}>
          <p>
            Your browser doesn't support HTML video. Here is a
            <a href={vidLink}>link to the video</a> instead.
          </p>
        </video>

      </Container>

      <Footer />

    </Box>
  </>
}
