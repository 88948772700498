export enum ActionTypes {
    REQUEST_START,
    REQUEST_FINISH,
    CLEAR
}

export interface Action {
    type: ActionTypes
    payload?: any;
}

export interface RequestState {
    loading: boolean;
    data: any | null;
    error: any | null;
}

export function reducer(state: RequestState, action: Action) {
    const { type, payload } = action;

    switch (type) {
        case ActionTypes.REQUEST_START:
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            };

        case ActionTypes.REQUEST_FINISH:
            return {
                ...state,
                loading: false,
                data: payload.data,
                error: payload.error
            };

        case ActionTypes.CLEAR:
            return {
                loading: false,
                data: null, error: null
            };
    
        default:
            return state;
    }
}
