import { FC, lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const MarksPage = lazy(() => import("./Marks"));

export const Marks: FC<Pick<RouteProps, "path">> =
    ({ path }) => {
        const { user } = useCurrentUser();

        return <Route path={path}>
            <Switch>
                {
                    user?.permissions?.marks &&
                    <Route path={`${path}/`} component={MarksPage} />
                }

                <Route path='*'><NotFound /></Route>
            </Switch>
        </Route>
    }
