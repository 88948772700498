import { useState, useRef } from "react";
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useSearchUsers } from "../../api";
import Stack from "@mui/material/Stack";

type Option = { _id: string, email: string } & (
  | { isCompany: true; companyName: string }
  | { isCompany?: false; firstName: string; lastName: string }
);

export const UserAutoComplete: React.FC<Pick<AutocompleteProps<Option, false, false, false, any>, 'value' | 'onChange' | 'sx' | 'renderInput'>> = (props) => {
  const [value, setValue] = useState<string>("");
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const { data, request } = useSearchUsers();

  let options: Option[] =  [];
  if (data) options = data.results;
  
  return <>
    <Autocomplete
      size='small'
      inputValue={value}
      onInputChange={(_event, newValue) => {
        setValue(newValue);
        if (debounceRef.current !== null) {
          clearTimeout(debounceRef.current);
        }
        debounceRef.current = setTimeout(
          () => request(null, { query: newValue, _skip: 0, __limit: 10 }),
          500
        );
      }}
      placeholder="Search for a user by name or email"
      noOptionsText="No users found"
      options={options}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.isCompany ? option.companyName : `${option.firstName} ${option.lastName}`}
      renderOption={(props, option) => <li {...props}>
        <Stack direction='column'>
          <span>{option.isCompany ? option.companyName : `${option.firstName} ${option.lastName}`}</span>
          <span style={{ fontSize: '0.75rem' }}>{option.email}</span>
        </Stack>
      </li>}
      {...props}
    />
  </>
};
