import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useGetCurrentUser } from '../api';
import { RoleTypes } from '../roles';
import { getByKey, saveOrUpdate } from './Storage';


interface CurrentUserState {
    /**
     * `ready` is `false` while the app tries to
     * retrieve local login state
     */
    ready?: boolean;

    user?: any;

    setUser?: (user: any) => void;
}

const context = createContext<CurrentUserState>({});

const PERMISSIONS: { [x in RoleTypes]: any } = {
    'user': {
        showStaffBadge: false,
        subscribe: true,
        support: true
    },
    'staff': {
        guides: 'staff',
        subscribe: false
    },
    'dataentry': {
        marks: true,
        guides: 'staff',
        subscribe: false
    },
    'support': {
        users: true,
        plans: 'read',
        invoices: true,
        subscriptions: true,
        guides: 'staff',
        subscribe: false
    },
    'admin': {
        logs: true,
        marks: true,
        users: true,
        plans: true,
        invoices: true,
        subscriptions: true,
        guides: 'staff',
        subscribe: false
    },
    'superadmin': {
        logs: true,
        marks: true,
        users: true,
        plans: true,
        invoices: true,
        subscriptions: true,
        guides: 'staff',
        subscribe: false
    }
}


export const CurrentUserProvider: React.FC<{ children: any }> = ({ children }) => {
    const [user, doSetUser] = useState<any>();
    const [ready, setReady] = useState(false);

    const setUser = useCallback((user: any) => {
        if (!user) {
            doSetUser(user);
            return;
        }

        doSetUser({
            ...user, permissions: PERMISSIONS[user.role as RoleTypes]
        });
    }, [doSetUser]);

    useEffect(() => {
        if (user) {
            try {
                saveOrUpdate('user', user);
            } catch (e: any) {
                console.error('Failed to persist user: ', e.message);
            }
        } else if (!ready) {
            try {
                const result: any = getByKey('user');
                setUser(result);
                setReady(true);
            } catch (err: any) {
                console.error('Failed to retrieve logged in user: ', err.message);
                setReady(true);
            }
        } else {
            saveOrUpdate('user', user);
        }
    }, [user, ready, setUser, setReady]);

    return <context.Provider value={{ ready, user, setUser }}>
        <UpdateUser />
        {children}
    </context.Provider>
}

export const useForceLogin = () => {
    const history = useHistory();
    const location = useLocation();
    return useCallback(() => {
        history.push(`/auth/login?redirectTo=${location.pathname}${location.search}`);
    }, [history, location]); 
}

export const useCurrentUser = (forceLogin?: boolean, forceVerified: boolean = true) => {
    const state = useContext(context);
    const doForceLogin = useForceLogin();
    const history = useHistory();
    const location = useLocation();

    if (forceLogin && state.ready && (!state.user || !state.user?.email))
        doForceLogin();

    if (forceLogin && forceVerified && state.ready && !state.user?.isEmailVerified)
        history.push(`/verify-email?redirectTo=${location.pathname}${location.search}`);

    return state;
}

const UpdateUser = () => {
    const { user, setUser } = useCurrentUser();
    const { request } = useGetCurrentUser();

    useEffect(() => {
        if (user) return;
        if (!setUser) return;

        let isMounted = true;

        const updateUser = async () => {
            try {
                const response = await request();
                // console.log(isMounted);
                // if (!isMounted) return;

                setUser(response.data.user);
            } catch (error: any) { }
        }
        updateUser();

        return () => {
            isMounted = false;
        }
    }, [user, setUser, request]);

    return <></>;
}
