import React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';
import { unformatAmount } from '../../helpers';
import { useFormStyles } from './styles';
import { CheckboxProps, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles'
import { CSSProperties } from '@mui/styles/withStyles';


type FieldProps = TextFieldProps & {
    className?: string;
    name: string;
}

export const Field: React.FC<FieldProps> = ({ name, helperText, ...props }) => {
    const [field, meta] = useField(name);
    const classes = useFormStyles();

    const isError = meta.touched && meta.error ? true : false;
    if (isError) helperText = meta.error;

    return (
        <TextField
            variant="standard"
            className={clsx(classes.field, props.className)}
            error={isError}
            helperText={helperText}
            {...field}
            {...props} />
    );
}

export const AmountField: React.FC<FieldProps> = ({ name, helperText, ...props }) => {
    const [field, meta, action] = useField(name);

    const isError = meta.touched && meta.error ? true : false;
    if (isError) helperText = meta.error;

    const formatter = new Intl
        .NumberFormat('en-GB', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

    return (
        <TextField
            variant="standard"
            error={isError}
            helperText={helperText}
            {...field}
            {...props}
            onFocus={() => {
                if (!field.value) return;
                action.setValue(
                    `${unformatAmount(field.value)}`);
            }}
            onBlur={() => {
                if (!field.value) {
                    action.setValue('0.00');
                    return;
                }

                const parsed = parseFloat(field.value
                    .replaceAll(',', '')
                    .replaceAll(' ', '')
                );
                if (isNaN(parsed)) {
                    action.setValue('0.00');
                    return;
                }
                const formattedValue = formatter.format(parsed);

                action.setValue(formattedValue);
            }} />
    );
}

export interface SelectProps {
    className?: string;
    disabled?: boolean;
    name: string;
    label?: string;
    helperText?: string;
    displayEmpty?: boolean;
    style?: CSSProperties;
    children: any;
}

export const SelectField: React.FC<SelectProps> = ({
    className,
    disabled,
    children,
    name,
    label,
    helperText,
    displayEmpty,
    style
}) => {
    const [field, meta] = useField(name);

    const isError = meta.touched && meta.error ? true : false;
    if (isError) helperText = meta.error;

    return (
        <FormControl
            variant="standard"
            className={className}
            disabled={disabled}
            error={isError}
            style={style}>
            {
                label && <InputLabel shrink id={`${name}-label-id`}>
                    {label}
                </InputLabel>
            }
            <Select
                variant="standard"
                labelId={`${name}-label-id`}
                id={`${name}-id`}
                displayEmpty={displayEmpty}
                {...field}>
                {children}
            </Select>
            {
                helperText && <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    );
}

const useCheckboxStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    error: {
        borderColor: 'red'
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});


export function StyledCheckbox({ isError, ...props }: CheckboxProps & { isError?: boolean }) {
    const classes = useCheckboxStyles();

    return (
        <Checkbox
            className={clsx(classes.root, { [classes.error]: isError })}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}


export interface CheckboxFieldProps extends CheckboxProps {
    name: string;
    helperText?: string;
    children: any;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ children, helperText, name, ...props }) => {
    const [field, meta] = useField(name);
    const classes = useFormStyles();

    const isError = meta.touched && meta.error ? true : false;
    if (isError) helperText = meta.error;

    return <Box className={classes.field}>
        <FormControlLabel
            classes={{
                label: classes.label
            }}
            control={
                <StyledCheckbox
                    isError={isError}
                    checked={field.value}
                    onChange={field.onChange}
                    name={name} {...props} />
            }
            label={<>{children}</>}
        />

        <FormHelperText error={isError}>{helperText}</FormHelperText>
    </Box>
}
