import { useHistory } from "react-router";
import { useApi } from "./api";
import { useCurrentUser } from "../state/AppState";


export const useGetCurrentUser = () => useApi({
    method: 'get', route: '/auth/me',
    successMessage: null, errorMessage: null
});

export const useLogin = () => {
    const { request, ...state } = useApi({
        method: 'post', route: '/auth/login',
        errorMessage: 'Failed to Login',
        successMessage: 'Login Successful',
    });
    const { setUser } = useCurrentUser();

    const login = async (data: { email: string, password: string, forceLogin?: boolean }) => {
        const response = await request(data);

        if (!setUser || !response.data?.user)
            throw new Error('Failed to Login');

        setUser(response.data.user);
    }

    return { ...state, login };
}

export const useSignup = () => {
    const { request, ...state } = useApi({
        method: 'post', route: '/auth/signup',
        errorMessage: 'Failed to Signup',
        successMessage: 'Signup Successful',
    });
    
    const signup = async (data: any) => {
        await request(data);
    }

    return { ...state, signup };
}

export const useSignupWithToken = (tokenId: string) => {
    const { request, ...state } = useApi({
        method: 'post', route: `/auth/signup/${tokenId}`,
        errorMessage: 'Failed to Signup',
        successMessage: 'Signup Successful',
    });
    
    const signup = async (data: any) => {
        await request(data);
    }

    return { ...state, signup };
}


export const useLogout = () => {
    const { request, ...state } = useApi({
        method: 'get', route: '/auth/logout'
    });
    const { setUser } = useCurrentUser();
    const history = useHistory();

    const logout = async () => {
        try {
            await request();
            history.push('/');
            if (setUser) setUser(undefined);
        } catch (error: any) {}
    }


    return { ...state, logout }
}

export const useRequestChangePasswordLink = () => useApi({
    method: 'post', route: '/users/forgot-password',
    successMessage: null
});

export const useDoChangePasswordLink = (tokenId: string) => useApi({
    method: 'post', route: `/users/reset-password`,
    params: { tokenId }, successMessage: null
});

export const useRequestVerificationEmail = (email: string) => useApi({
    method: 'get', route: `/users/request-email-verification/${email}`
});

export const useCreateUser = () => useApi({
    method: 'post', route: '/users/'
});

export const useGetUsers = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/users',
    params, loadOnMount: load,
    successMessage: null
});

export const useSearchUsers = () => useApi({
    method: 'get', route: '/users/search',
    successMessage: null
});

export const useUpdateUser = () => useApi({
    method: 'patch', route: '/users/'
});

export const useDeleteUser = (id: string, name: string) => useApi({
    method: 'delete', route: `/users/${id}`,
    successMessage: `You successfully deleted ${name}`,
    errorMessage: `Failed to delete ${name}`
});

export const useChangePassword = () => useApi({
    method: 'post', route: '/users/change-password'
});

export const useEmailUnsubscribe = (tokenId: string) => useApi({
    method: 'get', route: `/users/unsubscribe/${tokenId}`
});

export const useSaveSearch = () => useApi({
    method: 'post', route: '/saved-searches'
});

export const useGetSavedSearches = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/saved-searches',
    params, loadOnMount: load, successMessage: null
});

export const useRemoveSavedSearches = () => useApi({
    method: 'delete', route: '/saved-searches'
});

export const useAddMembers = (orgEmail: string) => useApi({
    method: 'post', route: `/users/${orgEmail}/members`,
    successMessage: 'Invitations sent successfully',
    errorMessage: 'Failed to send invitations'
});

export const useGetMembers = (orgEmail?: string, load?: boolean) => useApi({
    method: 'get', route: '/users/members',
    loadOnMount: load, successMessage: null,
    errorMessage: 'Failed to retrieve members'
});

export const useRemoveMembers = (orgEmail?: string) => useApi({
    method: 'delete', route: `/users/${orgEmail}/members`,
});

export const useGetInvites = (email?: string, load?: boolean) => useApi({
    method: 'get', route: `/membership-invitations`,
    params: { receipient: email, status: 'pending' },
    loadOnMount: load, errorMessage: 'Failed to retrieve invites'
});

export const useUpdateInvite = (_id?: string) => useApi({
    method: 'patch', route: `/membership-invitations`,
    params: { _id }, errorMessage: 'Failed to update invite'
});

export const useAcceptInvite = (_id: string) => useApi({
    method: 'post', route: `/membership-invitations/${_id}/accept`,
    successMessage: 'Invite accepted successfully',
});

export const useRejectInvite = (_id: string) => useApi({
    method: 'post', route: `/membership-invitations/${_id}/reject`,
    successMessage: 'Invite rejected successfully',
});

export const useUpdateNotifications = () => useApi({
    method: 'patch', route: '/notifications',
    successMessage: null
});

interface SearchParams {
    query?: string
    type?: string
    reference?: string
}

export const useSearch = (params?: SearchParams, load?: boolean) => useApi({
    method: 'get', route: '/search', loadOnMount: load,
    params, successMessage: null,
    errorMessage: 'Failed to fetch search results'
});

export const useCreateMark = () => useApi({
    method: 'post', route: '/marks'
});

export const useUpdateMarks = () => useApi({
    method: 'patch', route: '/marks'
});

export const useDeleteMarks = () => useApi({
    method: 'delete', route: '/marks'
});

export const useSearchMarks = () => useApi({
    method: 'get', route: '/marks/search',
    successMessage: null
});

export const useCreatePlan = () => useApi({
    method: 'post', route: '/plans'
});

export const useGetPlans = (params?: any) => useApi({
    method: 'get', route: '/plans', params,
    loadOnMount: true, successMessage: null
});

export const useUpdatePlans = () => useApi({
    method: 'patch', route: '/plans'
});

interface PayParams {
    query?: string,
    redirectTo?: string
}

export const usePayPlan = (planId: string, params?: PayParams) => useApi({
    method: 'post', route: `/plans/${planId}/pay`,
    params
});

export const useSubscribeToPlan = (planId: string, params?: PayParams) => useApi({
    method: 'get', route: `/plans/${planId}/subscribe`,
    params
});


export const useConfirmPay = (reference: string) => useApi({
    method: 'get', route: `/plans/${reference}/confirm-pay`,
    errorMessage: 'Failed to confirm payment status'
});

export const useCapturePayment = () => useApi({
    method: 'post', route: `/plans/capture-payment`,
    errorMessage: 'Failed to capture payment'
});

export const useGetSubscriptions = (params?: any, load: boolean = true) => useApi({
    method: 'get', route: '/subscriptions',
    params, loadOnMount: load, successMessage: null
});

export const useSearchSubscriptions = () => useApi({
    method: 'get', route: '/sales',
    loadOnMount: true, successMessage: null
});

export const useSearchInvoices = () => useApi({
    method: 'get', route: '/invoices/search',
    loadOnMount: true, successMessage: null
});

export const useCreateSale = () => useApi({
    method: 'post', route: '/sales'
});

export const useRevokeSale = () => useApi({
   method: 'post', route: `/sales/revoke` 
});

export const useGetSales = (params?: any, load: boolean = true) => useApi({
    method: 'get', route: '/sales',
    params, loadOnMount: load, successMessage: null
});

export const useGetActiveSale = (userId: string, load: boolean = true) => useApi({
    method: 'get', route: `/sales/active/${userId}`,
    loadOnMount: load, successMessage: null
});

export const useUnsubscribe = (subId: string) => useApi({
    method: 'get', route: `/subscriptions/${subId}/unsubscribe`,
    successMessage: 'Subscription will not be renewed'
}); 

export const useCancelSubscription = (subId: string) => useApi({
    method: 'get', route: `/subscriptions/${subId}/cancel`,
    successMessage: 'Subscription has been cancelled'
}); 

export const useShareSubscription = (subId: string, email: string) => useApi({
    method: 'get', route: `/subscriptions/${subId}/share/${email}`,
    successMessage: 'Subscription has been shared successfully'
});

export const useUnshareSubscription = (subId: string, email: string) => useApi({
    method: 'get', route: `/subscriptions/${subId}/unshare/${email}`,
    successMessage: 'Subscription has been unshared successfully'
});


export const useGetPayments = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/payments',
    params, loadOnMount: load, successMessage: null
});

export const useGetInvoices = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/invoices',
    params, loadOnMount: load, successMessage: null
});

export const useRevertToPendingInvoice = (invoiceId: string) => useApi({
    method: 'post', route: `/invoices/revert/${invoiceId}`
});

export const useFulfillInvoice = (invoiceId: string) => useApi({
    method: 'post', route: `/invoices/fulfill/${invoiceId}`
});

export const useCancelInvoice = (invoiceId: string) => useApi({
    method: 'post', route: `/invoices/cancel/${invoiceId}`
});

export const useGetJournals = () => useApi({
    method: 'get', route: '/journals',
    successMessage: null, loadOnMount: true
});

export const useCreateJournal = () => useApi({
    method: 'post', route: '/journals',
    successMessage: 'Journal has been created successfully'
});

export const useUpdateJournal = () => useApi({
    method: 'patch', route: '/journals',
    successMessage: "Journal updated successfully"
});

export const useImportMarks = () => useApi({
    method: 'post', route: '/marks/import',
    successMessage: null
});

export const useGetJobs = () => useApi({
    method: 'get', route: '/jobs',
    successMessage: null
});

export const useReprocessJob = () => useApi({
    method: 'patch', route: '/jobs/reprocess',
    successMessage: "Job queued successfully"
});

export const useBulkDeleteMarks = () =>  useApi({
    method: 'post', route: '/marks/delete'
});

export const useFetchLogs = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/audit-trails',
    params, loadOnMount: load, successMessage: null
});

export const useFetchActivityGraph = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/audit-trails/graph',
    params, loadOnMount: load, successMessage: null
});

export const useGetErrors = (params?: any, load?: boolean) => useApi({
    method: 'get', route: '/errors',
    loadOnMount: load, successMessage: null,
    params
});

export const useLogError = () => useApi({
    method: 'post', route: '/errors',
    successMessage: null, errorMessage: null
}); 

export const useUpdateErrors = () => useApi({
    method: 'patch', route: '/errors'
});

export const useContactUs = () => useApi({
    method: 'post', route: '/support'
});

export const useNewsLetterSubcribe = () => useApi({
    method: 'post', route: '/newsletter-subscriptions',
    successMessage: 'You are now subscribed to our newsletter'
});
