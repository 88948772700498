import {
    Box,
    Button,
    Typography,
    useTheme
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { UserHeader } from "../headers";


export const NotFound: React.FC = () => {
    const theme = useTheme();
    const history = useHistory();

    const goHome = () => history.push('/');

    return <Box
        bgcolor={theme.palette.background.default}
        minHeight='100vh'
        pb={8}
    >
        <UserHeader />

        <Box mt='20vh'>
            <Typography align='center'>
                <Box
                    component='span'
                    fontSize='5rem'
                >
                    404
                </Box>
            </Typography>

            <Box>
                <Typography align='center'>
                    This page does not exist
                </Typography>
            </Box>

            <Box mt={2}
                display='flex'
                justifyContent='center'
            >
                <Button
                    onClick={goHome}
                    color='primary'
                >
                    Go home
                </Button>
            </Box>
        </Box>
    </Box>
}
