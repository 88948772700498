import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useLocationQuery } from '../../helpers';
import { useCurrentUser } from '../../state/AppState';
import { AuthContainer } from './AuthContainer';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { Login } from './Login';
import { Signup } from './Signup';
import { TransitionRoute } from './TransitionRoute';
import { RegistrationComplete } from './Confirmed';

export const Auth: React.FC<Pick<RouteProps, 'path'>> = ({ path }) => {
    const { ready, user } = useCurrentUser();
    const { redirectTo } = useLocationQuery();

    // if (ready && user)
    //     return <Redirect to={redirectTo || '/dashboard'} />

    return <Route path={path}>
        <AuthContainer>
            <TransitionRoute path={`${path}login`}>
                <Login />
            </TransitionRoute>
            <TransitionRoute path={`${path}signup`}>
                <Signup />
            </TransitionRoute>
            <TransitionRoute path={`${path}registration-complete`}>
                <RegistrationComplete />
            </TransitionRoute>
            <TransitionRoute path={`${path}forgot-password`}>
                <ForgotPassword />
            </TransitionRoute>
            <TransitionRoute path={`${path}reset-password`}>
                <ResetPassword />
            </TransitionRoute>
        </AuthContainer>
    </Route>
}
