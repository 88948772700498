import { InputBase, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { useLocationQuery, useUpdateLocationQuery } from '../../helpers';


const CustomInput = withStyles({
    input: {
        padding: '5px 10px'
    }
})(InputBase);

export const SearchTypeSelector: React.FC = () => {
    let { type } = useLocationQuery();
    const update = useUpdateLocationQuery();

    type = type || 'basic';

    const handleOnChange =
        (e: SelectChangeEvent) => {
            const newType = e.target.value;
            update({ type: newType });
        }

    return (
        <Select
            variant="standard"
            value={type}
            onChange={handleOnChange}
            input={<CustomInput />}>
            <MenuItem value='basic'>Basic</MenuItem>
            <MenuItem value='extended'>Extended</MenuItem>
        </Select>
    );
}
