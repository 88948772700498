import { lazy } from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import { NotFound } from "../../components/utils";
import { useCurrentUser } from "../../state/AppState";

const Import = lazy(() => import("./import"));
const JournalsPage = lazy(() => import("./JournalsPage"));

export const Journals: React.FC<Pick<RouteProps, 'path' | 'exact'>> =
    ({ path, ...props }) => {
        const { user } = useCurrentUser(true);

        return <Route path={path} {...props}>
            <Switch>
                {/* <Route path={path} /> */}
                {
                    user?.permissions?.marks &&
                    <Route path={`${path}/import`} component={Import} />
                }
                {
                    user?.permissions?.marks &&
                    <Route path={`${path}/`} component={JournalsPage} />
                }
                <Route path='*'><NotFound /></Route>
            </Switch>
        </Route>
    }
