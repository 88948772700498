import React, { useState } from "react";
import * as yup from 'yup';
import { Box, FormHelperText, Paper, Tabs, Tab, Typography } from "@mui/material";
import { Formik, Form, FormikHelpers } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useSignup, useSignupWithToken } from '../../api';
import { FormError } from '../../components/forms/FormError';
import { useLocationQuery } from "../../helpers";
import { CheckboxField, Field, SpinnerButton } from '../../components/forms';
import { TabPanel } from '../../components/TabPanel';
import { useAuthStyles } from './styles';


type Values = {
  firstName: string,
  lastName: string,
  companyName: string,
  email: string,
  password: string,
  confirmPassword: string,
  acceptTerms: boolean,
  captchaToken: string,
}

const firstName = yup.string().required('First name is required');
const lastName = yup.string().required('Last name is required');

const acceptTerms = yup.boolean()
  .isTrue('You must read and agree to our terms and conditions');
const email = yup.string().email().required('Email is required');

// TODO ensure password strength
const password = yup.string()
  .required('Password is required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  );
const confirmPassword = yup.string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('Re-enter your password');

const captchaToken = yup.string().required('Complete captcha challenge');

const userValidationSchema = yup.object({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  acceptTerms,
  captchaToken
});

const userWithTokenValidationSchema = yup.object({
  firstName,
  lastName,
  password,
  confirmPassword,
  acceptTerms,
  captchaToken
});

const companyValidationSchema = yup.object({
  companyName: yup.string().required('Company name is required'),
  email,
  password,
  confirmPassword,
  acceptTerms,
  captchaToken
});


export const Signup: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { tokenId, redirectTo } = useLocationQuery();
  const classes = useAuthStyles();
  const history = useHistory();
  const { signup, ...signupProps } = useSignup();
  const { signup: signupWithToken, ...signupWithTokenProps } = useSignupWithToken(tokenId || '');

  const loading = signupProps.loading || signupWithTokenProps.loading;
  const error = signupProps.error || signupWithTokenProps.error;

  const initialValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
    captchaToken: ''
  };

  const redirectToQueryString = redirectTo ? `?redirectTo=${redirectTo}` : '';

  const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    try {
      if (tokenId) {
        await signupWithToken(values);
      } else {
        await signup({
          ...values,
          isCompany: currentTab === 1
        });
      }

      history.push(`/auth/registration-complete${redirectToQueryString}`);
    } catch (error: any) {
      if (error?.errors)
        actions.setErrors(error.errors);
    }
  }

  let validationSchema: any = currentTab === 0
    ? userValidationSchema
    : companyValidationSchema;
  if (tokenId) validationSchema = userWithTokenValidationSchema;

  return <Paper className={classes.root}>

    <Typography className={classes.header}
      variant='h4'>
      Get Started Now
    </Typography>

    <Typography variant='caption' sx={{ mb: 2 }}>
      Registration  is exclusively available to corporate clients and international  intellectual property (IP) agencies
    </Typography>

    {
      !tokenId &&
      <Tabs
        value={currentTab}
        onChange={(_event, newValue) => setCurrentTab(newValue)}
        indicatorColor='primary'
        textColor='secondary'
        centered
      >
        <Tab label='Individual' />
        <Tab label='Company' />
      </Tabs>
    }

    <FormError message={error?.message || error} />

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>

      {
        ({ errors, setFieldValue }) => (
          <Form className={classes.form}>

            <TabPanel value={currentTab} index={0}>
              <Field
                name='firstName'
                label='First name'
                variant='outlined'
              />

              <Field
                name='lastName'
                label='Last name'
                variant='outlined'
              />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <Field
                name='companyName'
                label='Company name'
                variant='outlined'
              />
            </TabPanel>

            {
              !tokenId &&
              <Field
                name='email'
                label='Email'
                placeholder='bola@gmail.com'
                variant='outlined'
              />
            }

            <Field
              name='password'
              label='Password'
              type='password'
              variant='outlined'
            />

            <Field
              name='confirmPassword'
              label='Confirm Password'
              type='password'
              variant='outlined'
            />

            <Box mt={2}>
              <ReCaptchaV2
                sitekey={process.env.REACT_APP_SITE_KEY || ''}
                onChange={(token) => setFieldValue('captchaToken', token || '')}
              />
              <FormHelperText error={true}>{errors.captchaToken}</FormHelperText>
            </Box>

            <CheckboxField name='acceptTerms'>
              I agree to your <Link to='/terms-and-conditions'>Terms and Conditions</Link>
            </CheckboxField>

            <SpinnerButton
              className={classes.submitBtn}
              type='submit'
              color='primary'
              variant='contained'
              loading={loading}>
              Create Account
            </SpinnerButton>
          </Form>
        )
      }

    </Formik>

    <Typography align='center' className={classes.link}>
      Already have an account? <Link to={`/auth/login${redirectToQueryString}`}>Login</Link>
    </Typography>

  </Paper>
}
