import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { useDoChangePasswordLink } from '../../api';
import { FormError } from '../../components/forms/FormError';
import { Field, SpinnerButton } from '../../components/forms';
import { useAuthStyles } from './styles';
import { useLocationQuery } from '../../helpers';


const validationSchema = yup.object({
    newPassword: yup.string().required('Enter your new password'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Re-enter your password')
});

export const ResetPassword: React.FC = () => {
    const classes = useAuthStyles();
    const history = useHistory();
    const { tokenId, redirectTo } = useLocationQuery();
    const { loading, request, error } = useDoChangePasswordLink(tokenId as string);

    const initialValues = {
        newPassword: '', confirmPassword: ''
    };

    const onSubmit = async (values: any) => {
        try {
            await request(values);
            history.push('/auth/login');
        } catch (error: any) {
            console.log(error);
        }
    };

    const redirectToQueryString = redirectTo ? `?redirectTo=${redirectTo}` : '';

    const form = <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>

        <Form className={classes.form}>

            <Typography align='center'>
                Set your new password
            </Typography>

            <Field
                name='newPassword'
                label='New Password'
                type='password'
                variant='outlined'
            />

            <Field
                name='confirmPassword'
                label='Confirm New Password'
                type='password'
                variant='outlined'
            />

            <SpinnerButton
                className={classes.submitBtn}
                type='submit'
                color='primary'
                variant='contained'
                loading={loading}
            >
                Change Password
            </SpinnerButton>
        </Form>

    </Formik>;

    return <Paper className={classes.root}>
        <Typography className={classes.header} variant='h4'>
            Reset Password
        </Typography>

        <FormError message={error?.message || error} />

        {form}

        <Typography align='center' className={classes.link}>
            Remembered your password? <Link to={`/auth/login${redirectToQueryString}`}>
                Login</Link>
        </Typography>
        <Typography align='center' className={classes.link}>
            Don't have an account? <Link to={`/auth/signup${redirectToQueryString}`}>
                Signup</Link>
        </Typography>

    </Paper>
}
